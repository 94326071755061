import fetcher from '../../../helper/fetcher'
import React, { useEffect, useState } from 'react'
import WarningModal from '../../../component/WarningModal'
import { useSnackbar } from '../../../context/SnackBarContext'
import AllCustomer from '../../../features/Inventory/ManageCustomerUI/AllCustomer'

const ManageCustomer = () => {
    const { showSuccess, showError } = useSnackbar();
    const [refetch, setRefetch] = useState(1)
    const [state, setState] = useState({
        query: "",
        isModalOpen: false,
        isLoading: false,
        id: null
    })
    const [data, setData] = useState({
        results: [],
        page: 0,
        limit: 0,
        totalPages: 0,
        totalResults: 0,
    })
    const [params, setParams] = useState({
        page: 1,
        limit: 20,
    })

    const setPage = (page) => {
        setParams({ ...params, page: page });
    };

    const updateState = (data) => {
        setState((prevState) => {
            const newState = { ...prevState, ...data }
            return newState
        })
    }

    const handleGetAllCustomer = (val) => {
        fetcher.get(`/inventory/customer/get_all_customer?query=${state.query}&page=${val == undefined ? params.page : val}&limit=${params.limit}`)
            .then((res) => {
                setData({
                    ...res.data
                });
            })
            .catch((error) => {
                showError(error?.res?.data?.mgs);
            }).finally(() => {
                updateState({ isLoading: false, query: "" })
            })
    }

    useEffect(() => {
        handleGetAllCustomer()
    }, [refetch, params])

    const handleDeleteCustomer = () => {
        if (!state.id) return;
        updateState({ isLoading: true })
        fetcher.delete(`inventory/customer/delete_customer/${state.id}`)
            .then((res) => {
                showSuccess("Deleted successfully")
            })
            .catch((error) => {
                showError("Some Error Occured");
            }).finally(() => {
                setRefetch((pre) => pre + 1)
                updateState({ isLoading: false, isModalOpen: false })
            })
    }

    return (
        <div>
            <AllCustomer
                updateFunc={updateState}
                handleGetAllUsers={handleGetAllCustomer}
                parentState={state}
                data={data}
                setPage={setPage}
            />
            <WarningModal
                isOpen={state.isModalOpen}
                onClose={() => updateState({ isModalOpen: false })}
                modalTitle="Are you sure you want to delete this item?"
                onConfirm={handleDeleteCustomer}
                onCancel={() => updateState({ isModalOpen: false })}
                confirmText={state.isLoading ? "Deleting..." : "Delete"}
                cancelText="Cancel"
                isLoading={state.isLoading}
            />
        </div>
    )
}

export default ManageCustomer

