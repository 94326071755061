import { Input } from "antd";
import React, { useState } from "react";
import fetcher from "../../../helper/fetcher";
import { formateCustomerData } from "../../../utils/utils";
import { useSnackbar } from "../../../context/SnackBarContext";
import ManageAddress from "./ManageAddress";
import Tabs from "../../../component/Tabs";

const AddNewContact = ({ updateFunc }) => {
  const [activeTab, setActiveTab] = useState("General Info");
  const tabs = ["General Info", "Address"];

  const [generalInfo, setGeneralInfo] = useState({
    name: "",
    email: "",
    alternativeEmails: "",
    phoneNumber: "",
    gstIn: "",
    gstInCode:""
  });

  const [addresses, setAddresses] = useState({
    address1: "",
    address2: "",
    city: "",
    postalCode: "",
    state: "",
    country: "",
    isShippingSameAsBilling: false,
    shippingAddress1: "",
    shippingAddress2: "",
    shippingCity: "",
    shippingPostalCode: "",
    shippingState: "",
    shippingCountry: "",
  });


  const { showSuccess, showError } = useSnackbar();
  const [isLoading, setIsLaoding] = useState(false)

  // Handler for General Info
  const handleGeneralInfoChange = (e) => {
    const { name, value } = e.target;
    setGeneralInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handler for Address change
  const handleAddressChange = (field, value) => {
    setAddresses((prevAddresses) => ({
        ...prevAddresses,
        [field]: value
    }));
};


  const validateGeneralInfo = () => {
    if (!generalInfo.name.trim()) return "Name is required.";
    if (!generalInfo.phoneNumber.trim()) return "Phone number is required.";
    if (!/^\+?\d{10,12}$/.test(generalInfo.phoneNumber))
      return "Invalid Phone number";
    return null;
  };

  const validateAddress = () => {
    if (!addresses.address1.trim()) return "Billing Address 1 is required.";
    if (!addresses.city.trim()) return "Billing City is required.";
    if (!addresses.postalCode.trim()) return "Billing Postal Code is required.";
    if (!/^\d{5,6}$/.test(addresses.postalCode))
        return "Invalid Billing Postal Code.";
    if (!addresses.state.trim()) return "Billing State is required.";

    if (!addresses.isShippingSameAsBilling) {
        if (!addresses.shippingAddress1.trim()) return "Shipping Address 1 is required.";
        if (!addresses.shippingCity.trim()) return "Shipping City is required.";
        if (!addresses.shippingPostalCode.trim()) return "Shipping Postal Code is required.";
        if (!/^\d{5,6}$/.test(addresses.shippingPostalCode))
            return "Invalid Shipping Postal Code.";
        if (!addresses.shippingState.trim()) return "Shipping State is required.";
    }
    return null;
};

  const handleNext = () => {
    const generalInfoError = validateGeneralInfo();
    if (generalInfoError) {
      showError(generalInfoError);
      return;
    }
    setActiveTab(tabs[1])
  }

  const handleSaveAddress = () => {
    const generalInfoError = validateGeneralInfo();
    if (generalInfoError) {
      showError(generalInfoError);
      return;
    }

    const addressError = validateAddress();
    if (addressError) {
      showError(addressError);
      return;
    }
    setIsLaoding(true);
    const d = formateCustomerData(generalInfo, addresses)
    fetcher.post("/inventory/customer/create_customer", d)
      .then((res) => {
        showSuccess("Created successfully")
        updateFunc({ isDrawerOpen: false })
      })
      .catch((error) => {
        showError(error.response?.data?.message || "Some Error Occured");
      }).finally(() => {
        setIsLaoding(false)
      })
  }

  return (
    <div>
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />
      {/* Content */}
      {activeTab === "General Info" && (
        <div className="grid grid-cols-1 gap-4">
          <div>
            <label className="block text-sm text-gray-600 mb-1">Name *</label>
            <Input value={generalInfo.name} name="name" placeholder="Name"
              onChange={handleGeneralInfoChange} size="small" className="w-full rounded" />
          </div>
          <div>
            <label className="block text-sm text-gray-600 mb-1">Email Address </label>
            <Input value={generalInfo.email} name="email"
              onChange={handleGeneralInfoChange} size="small" type="email" className="w-full rounded" placeholder="Email" />
          </div>
          <div>
            <label className="block text-sm text-gray-600 mb-1">Alternative Email</label>
            <Input name="alternativeEmails"
              value={generalInfo.alternativeEmails}
              onChange={handleGeneralInfoChange} size="small" className="w-full rounded" placeholder="Alternative Email" />
          </div>
          <div>
            <label className="block text-sm text-gray-600 mb-1">Phone Number *</label>
            <div className="flex items-center">
              <Input name="phoneNumber"
                value={generalInfo.phoneNumber}
                onChange={handleGeneralInfoChange} size="small" className="w-full rounded" placeholder="Phone Number" />
            </div>
          </div>
          <div>
            <label className="block text-sm text-gray-600 mb-1">GST Number </label>
            <div className="flex items-center">
              <Input name="gstIn"
                value={generalInfo.gstIn}
                onChange={handleGeneralInfoChange} size="small" className="w-full rounded" placeholder="GST Number" />
            </div>
          </div>
          <div>
            <label className="block text-sm text-gray-600 mb-1">GST Code </label>
            <div className="flex items-center">
              <Input name="gstInCode"
                value={generalInfo.gstInCode}
                onChange={handleGeneralInfoChange} size="small" className="w-full rounded" placeholder="GST Code" />
            </div>
          </div>

          <div className="flex justify-between items-center my-5">
            <button
              onClick={() => handleNext()}
              className="bg-[#db3647] text-white px-4 py-2 rounded"
            >
              Next
            </button>
          </div>
        </div>
      )}

      {activeTab === "Address" && (
        <ManageAddress
          handleSaveAddress={handleSaveAddress}
          handleAddressChange={handleAddressChange}
          address={addresses}
          isLoading={isLoading}
          setAddresses={setAddresses}
        />
      )}
    </div>
  );
};

export default AddNewContact;