import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultipleSelect = ({ options, defaultValue, value, onChange, label, name, placeholder }) => {
  return (
    <Select
      labelId={`${label}`}
      id={`${label}`}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
      MenuProps={MenuProps}
      hiddenLabel
      displayEmpty
      fullWidth
      size="small"
      sx={{
        backgroundColor: "white",
        borderColor: "#6B7280", 
        height: "41px", 

        "& fieldset": {
          borderColor: "#6B7280 ",
        },
      }}
      name={name}
    >
      <MenuItem value="" disabled>
        {placeholder}
      </MenuItem>
      {options.map((option) => (
        <MenuItem key={option.name} value={option.name}>
          {option.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default MultipleSelect;
