import axios from "axios";
import React, { useState } from "react";
import { Input, Upload, Button } from "antd";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import { CircularProgress } from "@mui/material";
import MultipleSelect from "../../../component/MultipleSelect";
import { useSnackbar } from "../../../context/SnackBarContext";
import { apiUrl, getAuthToken, imageBaseUrl } from "../../../utils/utils";
import Tabs from "../../../component/Tabs";
import { useEffect } from "react";

const { TextArea } = Input;

const EditProductDetails = ({ setDrawerOpen, edit, setRefetch }) => {
    const [activeTab, setActiveTab] = useState("General Info");
    const tabs = ["General Info", "Accounting"];
    const { showSuccess, showError } = useSnackbar();
    const [selectedImage, setSelectedImage] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [preview, setPreview] = useState(null);
    const [error, setError] = useState("");

    const [formData, setFormData] = useState({
        fabricVariety: "",
        productType: "",
        transactionType: "",
        productName: "",
        description: "",
        availableStock: ""
    });
    const [accountFormData, setAccountFormData] = useState({
        purchaseAccount: "",
        purchasePrice: "",
        salesAccount: "",
        salesPrice: "",
        salesReturnAccount: "",
        purchaseReturnAccount: "",
    });


    useEffect(() => {
        if (edit) {
            setFormData({
                fabricVariety: edit.fabricVariety,
                productType: edit.category,
                transactionType: edit.transactionType,
                productName: edit.productName,
                description: edit.description,
                availableStock: edit.availableStock
            })

            setAccountFormData({
                purchaseAccount: (edit.purchaseAccount) && edit.purchaseAccount || '',
                purchasePrice: edit.purchasePrice && edit.purchasePrice || '',
                salesAccount: edit.salesAccount && edit.salesAccount || '',
                salesPrice: edit.salesPrice && edit.salesPrice || '',
                salesReturnAccount: edit.salesReturnAccount && edit.salesReturnAccount || '',
                purchaseReturnAccount: edit.purchaseReturnAccount && edit.purchaseReturnAccount || '',
            })
            setPreview(`${imageBaseUrl}images/${edit?.product_image?.url}`)
            setSelectedImage(`${imageBaseUrl}images/${edit?.product_image?.url}`)
        }
    }, [edit])

    console.log("ppro =====>>>", preview, edit?.product_image?.url)

    const transactionTypeOptions = [
        { name: "Sale" },
        { name: "Purchase" },
        { name: "Return" },
    ];

    const fabricOptions = [
        { name: "Fresh" },
        { name: "Synthetic" },
        { name: "Cotton" },
    ];

    const productTypeOptions = [
        { name: "Tracked" },
        { name: "Non-Tracked" },
        { name: "Bill Of Materials" },
    ]

    const handleSelectChange = (key, value) => {
        setFormData({ ...formData, [key]: value });
    };

    const handleInputChange = (key, event) => {
        setFormData({ ...formData, [key]: event.target.value });
    };

    const handleImageChange = (file) => {
        // Allowed image types
        const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];

        if (!allowedTypes.includes(file.type)) {
            setError("Only JPEG, JPG, and PNG files are allowed.");
            return false;
        }

        // Check file size (1MB = 1,048,576 bytes)
        if (file.size > 1048576) {
            setError("File size should not exceed 1MB.");
            return false;
        }

        setError("");
        setSelectedImage(file);
        setPreview(URL.createObjectURL(file));

        return false;
    };

    const handleRemoveImage = () => {
        setSelectedImage(null);
    };

    const handleChange = (key, value) => {
        setAccountFormData({ ...accountFormData, [key]: value });
    };
    const handleNext = () => {
        if (!formData.productName.trim()) {
            return showError("Product Name is required");
        }
        if (formData.productType == undefined
            || formData.productType == null
            || formData.productType == 'null'
            || !formData.productType.trim()) {
            return showError("Product Type is required");
        }
        if (formData.transactionType == undefined
            || formData.transactionType == null
            || formData.transactionType == 'null'
            || !formData.transactionType.trim()) {
            return showError("Transaction Type is required");
        }
        if (formData.fabricVariety == undefined
            || formData.fabricVariety == null
            || formData.fabricVariety == 'null'
            || !formData.fabricVariety.trim()) {
            return showError("Fabric Variety is required for Goods");
        }
        setActiveTab(tabs[1])
    }

    const handleAddProduct = () => {
        // Validation checks
        if (!formData.productName.trim()) {
            return showError("Product Name is required");
        }
        if (formData.productType == undefined
            || formData.productType == null
            || formData.productType == 'null'
            || !formData.productType.trim()) {
            return showError("Product Type is required");
        }
        if (formData.transactionType == undefined
            || formData.transactionType == null
            || formData.transactionType == 'null'
            || !formData.transactionType.trim()) {
            return showError("Transaction Type is required");
        }
        if (formData.fabricVariety == undefined
            || formData.fabricVariety == null
            || formData.fabricVariety == "null"
            || !formData.fabricVariety.trim()) {
            return showError("Fabric Variety is required for Goods");
        }

        const formDataForApi = new FormData();
        formDataForApi.append("productName", formData.productName || '');
        formDataForApi.append("description", formData.description || '');
        formDataForApi.append("category", formData.productType || '');
        formDataForApi.append("availableStock", formData.availableStock || '');
        formDataForApi.append("transactionType", formData.transactionType || '');
        formDataForApi.append("fabricVariety", formData.fabricVariety || '');
        formDataForApi.append("UOM", '');
        formDataForApi.append("purchaseAccount", accountFormData.purchaseAccount || '');
        formDataForApi.append("purchasePrice", accountFormData.purchasePrice || 0);
        formDataForApi.append("salesAccount", accountFormData.salesAccount || '');
        formDataForApi.append("salesPrice", accountFormData.salesPrice || 0);
        formDataForApi.append("salesReturnAccount", accountFormData.salesReturnAccount || '');
        formDataForApi.append("purchaseReturnAccount", accountFormData.purchaseReturnAccount || '');

        if (selectedImage) {
            formDataForApi.append("product_image", selectedImage);
        }

        if (!formData.productName.trim()) {
            return showError("Product Name Required")
        }
        setIsLoading(true)
        const token = getAuthToken()
        axios.put(`${apiUrl}/inventory/product/update_product/${edit._id}`, formDataForApi, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `${token}`,
            }
        })
            .then((res) => {
                showSuccess("Product Updated successfully")
                setDrawerOpen(false)
            })
            .catch((error) => {
                showError(error.response.data?.message || "Some Error Occured");
            }).finally(() => {
                setRefetch && setRefetch((prev) => prev + 1)
                setIsLoading(false)
            })
    }

    console.log("formData ====>>>", formData)

    return (
        <div className="px-2 mb-7">
            <Tabs tabs={tabs} setActiveTab={setActiveTab} activeTab={activeTab} />

            {activeTab === "General Info" && (
                <div>
                    <div>
                        {/* Product Type */}
                        <div className="mb-4">
                            <label className="block font-medium mb-1">Product Type *</label>
                            <MultipleSelect
                                options={productTypeOptions}
                                value={formData.productType}
                                onChange={(e) => handleSelectChange("productType", e.target.value)}
                                label="Product Type"
                            />
                        </div>

                        {/* Transaction Type */}
                        <div className="mb-4">
                            <label className="block font-medium mb-1">Transaction Type *</label>
                            <MultipleSelect
                                options={transactionTypeOptions}
                                value={formData.transactionType}
                                onChange={(e) => handleSelectChange("transactionType", e.target.value)}
                                label="Transaction Type"
                            />
                        </div>

                        {/* Auto-Numbering Format */}
                        <div className="mb-4">
                            <label className="block font-medium mb-1">
                                Auto-Numbering Format *
                            </label>
                            <Input value="P-000" disabled size="small" className="w-full rounded" />
                        </div>

                        {/* Product Name */}
                        <div className="mb-4">
                            <label className="block font-medium mb-1">Product Name *</label>
                            <Input
                                value={formData.productName}
                                onChange={(e) => handleInputChange("productName", e)}
                                placeholder="Enter Product Name"
                                size="small" className="w-full rounded"
                            />
                        </div>

                        {/* Product Name */}
                        <div className="mb-4">
                            <label className="block font-medium mb-1">Available Stock </label>
                            <Input
                                value={formData.availableStock}
                                onChange={(e) => handleInputChange("availableStock", e)}
                                placeholder="Enter Availabale Stock"
                                size="small" className="w-full rounded"
                            />
                        </div>

                        {/* Description */}
                        <div className="mb-4">
                            <label className="block font-medium mb-1">Description</label>
                            <TextArea
                                value={formData.description}
                                onChange={(e) => handleInputChange("description", e)}
                                rows={4}
                                placeholder="Enter Description"
                            />
                        </div>
                    </div>

                    {/* Custom Fields */}
                    <div className="col-span-2 mt-4">
                        <p className="font-bold text-[#db3647] text-[16px] mb-3 mt-5">Custom Fields</p>
                        <div>
                            <label className="block font-medium mb-1">Fabric Variety *</label>
                            <MultipleSelect
                                options={fabricOptions}
                                value={formData.fabricVariety}
                                onChange={(e) => handleSelectChange("fabricVariety", e.target.value)}
                                label="Fabric Variety"
                            />
                        </div>
                    </div>

                    {/* <div className="mt-6">
                        <div className="border-dashed border-2 border-gray-300 rounded p-4 text-center ">
                            <p className="text-sm text-gray-500 mb-2">Attach Image</p>
                            <Upload beforeUpload={handleImageChange} showUploadList={false}>
                                <Button icon={<PlusOutlined />} className="border-none text-[#db3647]">Add Image</Button>
                            </Upload>
                        </div>
                    </div> */}

                    <div className="mt-6">
                        <div className="border-dashed border-2 border-gray-300 rounded p-4 text-center">
                            <p className="text-sm text-gray-500 mb-2">Attach Image</p>
                            <Upload beforeUpload={handleImageChange} showUploadList={false}>
                                <Button icon={<PlusOutlined />} className="border-none text-[#db3647]">
                                    Add Image
                                </Button>
                            </Upload>
                            {selectedImage && (
                                <div className="mt-4 relative">
                                    <img src={preview} alt="Uploaded" className="w-[360px] h-28 object-cover rounded border border-gray-300" />
                                    <button
                                        onClick={handleRemoveImage}
                                        className="absolute top-0 right-0 bg-[#db3647] px-2 py-1 rounded-full text-white text-[14px] "
                                    >
                                        <CloseOutlined />
                                    </button>
                                </div>
                            )}
                        </div>
                        {error && <p style={{ color: "red" }}>{error}</p>}
                    </div>

                    <button
                        onClick={() => handleNext()}
                        className="bg-[#db3647] text-white px-4 py-2 rounded mt-5"
                    >
                        Next
                    </button>
                </div>
            )}

            {activeTab === "Accounting" && <div >
                {/* Buy Details */}
                <div className="mb-6">
                    <p className="font-bold text-[#db3647] text-[16px] mb-3 mt-5">Buy Details</p>
                    <div>
                        {/* Purchase Account */}
                        <div>
                            <label className="block font-medium mb-1">Purchase Account </label>
                            <Input
                                value={accountFormData.purchaseAccount}
                                onChange={(e) => handleChange("purchaseAccount", e.target.value)}
                                placeholder="Enter Purchase Account"
                                size="small" className="w-full rounded"
                            />
                        </div>
                        {/* Purchase Price */}
                        <div>
                            <label className="block font-medium mb-1 mt-4">Purchase Price </label>
                            <Input
                                type="text"
                                value={accountFormData.purchasePrice}
                                onChange={(e) => handleChange("purchasePrice", e.target.value)}
                                placeholder="Enter Purchase Price"
                                size="small" className="w-full rounded"
                            />
                        </div>
                    </div>
                </div>

                {/* Sell Details */}
                <div className="mb-6">
                    <p className="font-bold text-[#db3647] text-[16px] mb-3 mt-5">Sell Details</p>
                    <div >
                        {/* Sales Account */}
                        <div>
                            <label className="block font-medium mb-1">Sales Account </label>
                            <Input
                                value={accountFormData.salesAccount}
                                onChange={(e) => handleChange("salesAccount", e.target.value)}
                                placeholder="Enter Sales Account"
                                size="small" className="w-full rounded"
                            />
                        </div>
                        {/* Sales Price */}
                        <div>
                            <label className="block font-medium mb-1 mt-4 ">Sales Price </label>
                            <Input
                                type="text"
                                value={accountFormData.salesPrice}
                                onChange={(e) => handleChange("salesPrice", e.target.value)}
                                placeholder="Enter Sales Price"
                                size="small" className="w-full rounded"
                            />
                        </div>
                    </div>
                </div>

                {/* Return Details */}
                <div>
                    <p className="font-bold text-[#db3647] text-[16px] mb-3 mt-5">Return</p>
                    <div >
                        {/* Sales Return Account */}
                        <div>
                            <label className="block font-medium mb-1">
                                Sales Return Account
                            </label>
                            <Input
                                value={accountFormData.salesReturnAccount}
                                onChange={(e) =>
                                    handleChange("salesReturnAccount", e.target.value)

                                }
                                placeholder="Enter Sales Return Account"
                                size="small" className="w-full rounded"
                            />
                        </div>
                        {/* Purchase Return Account */}
                        <div>
                            <label className="block font-medium mb-1 mt-4">
                                Purchase Return Account
                            </label>
                            <Input
                                value={accountFormData.purchaseReturnAccount}
                                onChange={(e) =>
                                    handleChange("purchaseReturnAccount", e.target.value)
                                }
                                placeholder="Enter Purchase Return Account"
                                size="small" className="w-full rounded"
                            />
                        </div>
                    </div>
                </div>

                <div className="flex justify-between items-center pt-4">
                    <button
                        onClick={handleAddProduct}
                        className="bg-[#db3647] text-white px-4 py-2 rounded"
                    >
                        {isLoading && < CircularProgress size={12} />}Save
                    </button>
                </div>
            </div>
            }
        </div>
    );
};

export default EditProductDetails;



