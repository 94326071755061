import React, { useState } from "react";
import Search from "../../../component/Search";
import EditIcon from "@mui/icons-material/Edit";
import LongMenu from "../../../component/LongMenu";
import DeleteIcon from "@mui/icons-material/Delete";
import DynamicTable from "../../../component/DynamicTable";
import ProductDetails from "../InvoiceUI/ProductDetails";
import { SideDrawer } from "../../../component/SideDrawer/SideDrawer";
import PaginationFeature from "../../../component/PaginationFeature/PaginationFeature";
import EditProductDetails from "./EditProduct";
import axios from "axios";
import { apiUrl, getAuthToken, imageBaseUrl } from "../../../utils/utils";
import { FaFileExcel } from "react-icons/fa6";

const AllProduct = ({
    data,
    updateFunc,
    handleGetAllProduct,
    parentState,
    setPage,
    setRefetch
}) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false)
    const [edit, setEdit] = useState({})

    const handleRowSelect = (selectedIndexes) => {
        updateFunc({ selectedRows: selectedIndexes })
    };

    const handleOptions = (option, item) => {
        setEdit(item)
        updateFunc({ id: item._id })
        if (option.id == 1) {
            setIsEditDrawerOpen(true)
        } else if (option.id == 2) {
            updateFunc({ isModalOpen: true, id: item._id })
        }
    };

    const menuOptions = [
        { id: 1, title: "Edit", icon: <EditIcon /> },
        { id: 2, title: "Delete", icon: <DeleteIcon />, color: "red" }
    ]

    const columns = ["SR.NO.", "Product Image", "Product Code", "Product name", "Available Stock", "Product Type", "Actions"];
    const transformedData = data.results.map((item, index) => {
        return {
            SRNO: (data.page - 1) * 20 + (index + 1),
            ProductImage: {
                value: (
                    <div className=" lg:flex lg:flex-row justify-start items-center gap-2">
                        <img className="w-10 h-10" src={`${imageBaseUrl}images/${item?.product_image?.url}`} alt={item.name} />
                    </div>
                ),
            },
            ProductCode: item.productCode,
            ProductName: item.productName,
            AvailableStock: item.availableStock,
            Category: item.category,
            Actions: {
                value: (
                    <LongMenu
                        options={menuOptions}
                        handleOptions={(prev) => handleOptions(prev, item)}
                    />
                ),
                bold: false,
            },
        };
    });

    const token = getAuthToken()
    const handleDownload = async () => {
        try {
            const response = await axios.get(`${apiUrl}inventory/product/csv_product`, {
                responseType: 'blob',
                headers: {
                    'Accept': 'text/csv',
                    'Authorization': `${token}`
                }
            });

            // Create a download link
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
            const a = document.createElement('a');
            a.href = url;
            a.download = `product.csv`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            console.error('Error downloading CSV:', error);
        }
    };

    return (
        <div>
            {/* Header */}
            <div className="flex md:flex-row flex-col justify-between gap-3 mb-6 md:px-0 px-5 md:py-0 py-1">
                <h1 className="md:text-[26px] text-[20px] font-bold text-gray-800">Manage Product</h1>
                <button
                    className="bg-gray-800 text-white px-5 py-2 rounded hover:bg-[#191919]"
                    onClick={() => setIsDrawerOpen(true)}
                >
                    + Add Product
                </button>
            </div>

            <div className="bg-white shadow rounded-md md:p-6 p-2 min-h-screen">
                <div className="flex md:flex-row flex-wrap  justify-between items-center mb-5">
                    <h2 className=" font-bold text-[#db3647] ">
                        All Products
                    </h2>
                    <div className="mt-4 flex sm:flex-row flex-col justify-between items-center gap-4">
                        <div
                            onClick={handleDownload}
                            className="text-[14px] cursor-pointer border-2 border-[#db3647] bg-[#db3647] text-white px-4 py-1 rounded-md flex items-center gap-1 hover:bg-[#b92c3b] transition-all shadow-md"
                        >
                            <FaFileExcel size={20} />Download</div>

                        <Search
                            handleSeachButton={() => handleGetAllProduct(1)}
                            onChange={(e) => updateFunc({ query: e.target.value })}
                            value={parentState.query}
                            placeholder="Product Name"

                        />
                    </div>
                </div>

                {/* Dynamic Table */}
                <DynamicTable
                    data={transformedData}
                    columns={columns}
                    defaultRowSelected={[]}
                    onRowSelect={handleRowSelect}
                    checkbox={false}
                />
                <PaginationFeature
                    totalPage={data.totalPages}
                    setPage={setPage}
                />
            </div>

            <SideDrawer
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
                title="Product Details"
                headerHidden={true}
                customWidth={true}
            >
                <div style={{ padding: "20px" }}>
                    <ProductDetails
                        setRefetch={setRefetch}
                        setDrawerOpen={setIsDrawerOpen}
                    />
                </div>
            </SideDrawer>

            <SideDrawer
                open={isEditDrawerOpen}
                onClose={() => setIsEditDrawerOpen(false)}
                title="Edit Product Details"
                headerHidden={true}
                customWidth={true}
            >
                <div style={{ padding: "20px" }}>
                    <EditProductDetails
                        edit={edit}
                        setRefetch={setRefetch}
                        setDrawerOpen={setIsEditDrawerOpen} />
                </div>
            </SideDrawer>
        </div>
    );
};

export default AllProduct
