//  development server url

// export const apiUrl = "http://localhost:5000/api/v1/";
// export const imageBaseUrl = "http://localhost:5000/";

// render server url 

// export const apiUrl = "https://job-inventory-backend.onrender.com/api/v1/";
// export const imageBaseUrl = "https://job-inventory-backend.onrender.com/";

// production server url 

export const apiUrl = "https://api.jinraitech.com/api/v1/";
export const imageBaseUrl = "https://api.jinraitech.com/";


export const getAuthToken = () => {
    try {
        const tokenData = JSON.parse(localStorage.getItem("token"));
        if (tokenData?.token) {
            return `Bearer ${tokenData.token}`;
        }
    } catch (error) {
    }
    return null;
}

export const formateCustomerData = (generalInfo, addresses) => {
    const billingAddress = addresses;
    return {
        // general
        customerName: generalInfo.name || "",
        email: generalInfo.email || "",
        alternativeEmails: generalInfo.alternativeEmails || "",
        telephone: generalInfo.phoneNumber || "",
        gstIn: generalInfo.gstIn || "",
        gstInCode: generalInfo.gstInCode || "",

        // true or false
        isShippingSameAsBilling: billingAddress.isShippingSameAsBilling,

        // billing
        billingAddressOne: billingAddress.address1 || "",
        billingAddressSec: billingAddress.address2 || "",
        billingCity: billingAddress.city || "",
        billingPostalCode: billingAddress.postalCode || "",
        billingState: billingAddress.state || "",
        billingCountry: billingAddress.country,
        billingStateCode: "",

        //shipping
        shippingAddressOne: billingAddress.shippingAddress1 || "",
        shippingAddressSec: billingAddress.shippingAddress2 || "",
        shippingState: billingAddress.shippingState || "",
        shippingCity: billingAddress.shippingCity || "",
        shippingPostalCode: billingAddress.shippingPostalCode || "",
        shippingCountry: billingAddress.shippingCountry || "",
        shippingStateCode: "",
    };
};


export const calculateInvoiceAmount = ({ quantity, price, discount, tax }) => {
    const qty = parseFloat(quantity) || 0;
    const unitPrice = parseFloat(price) || 0;
    const discountRate = parseFloat(discount) || 0;
    const taxRate = parseFloat(tax) || 0;

    const totalPrice = qty * unitPrice;
    const discountAmount = (discountRate / 100) * totalPrice;
    const discountedPrice = totalPrice - discountAmount;
    const taxAmount = (taxRate / 100) * discountedPrice;

    return (discountedPrice + taxAmount).toFixed(2);
};

export const calculateInvoiceTotals = (data) => {
    let subTotal = 0;
    let totalDiscount = 0;
    let totalTax = 0;
    let tcs = 0;
    let roundOff = 0;

    data.forEach(item => {
        const itemPrice = parseFloat(item.price) || 0;
        const itemQty = parseFloat(item.quantity) || 0;
        const discountRate = parseFloat(item.discount) || 0;
        const itemTax = parseFloat(item.tax) || 0;

        const itemTotal = itemPrice * itemQty;
        const discountAmount = (discountRate / 100) * itemTotal;
        subTotal += itemTotal;
        totalDiscount += discountAmount;
        totalTax += ((itemTotal - discountAmount) * itemTax) / 100;
    });

    const totalBeforeTax = subTotal - totalDiscount;
    const total = totalBeforeTax + totalTax;

    return {
        subTotal: `${subTotal.toFixed(2)}`,
        totalDiscount: `${totalDiscount.toFixed(2)}`,
        beforeTax: `${totalBeforeTax.toFixed(2)}`,
        tax: `${totalTax.toFixed(2)}`,
        tcs: `${tcs.toFixed(2)}`,
        roundOff: roundOff,
        total: `${total.toFixed(2)}`
    };
};


export const convertNumberToWords = (amount) => {
    const words = [
        "Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine",
        "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen",
        "Seventeen", "Eighteen", "Nineteen",
    ];
    const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
    const scales = ["", "Thousand", "Million", "Billion"];

    if (amount === 0) return "Zero Rupees";

    const convertToWords = (num) => {
        if (num < 20) return words[num];
        if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? ` ${words[num % 10]}` : "");
        if (num < 1000) return `${words[Math.floor(num / 100)]} Hundred${num % 100 !== 0 ? ` ${convertToWords(num % 100)}` : ""}`;
        for (let i = 0; i < scales.length; i++) {
            const unit = 1000 ** (i + 1);
            if (num < unit) {
                return `${convertToWords(Math.floor(num / (1000 ** i)))} ${scales[i]}${num % (1000 ** i) !== 0 ? ` ${convertToWords(num % (1000 ** i))}` : ""}`;
            }
        }
    };

    return `${convertToWords(amount)} Rupees`;
};
 export const formatPuchaseOrder = (formData, tableData) => {
    return {
        product: tableData.map(item => ({
            productId: item.productId, 
            avg_pics: item.avg,
            col_qty: item.colQty,
            quantity: item.quantity,
            unit: item.unit,
            rate: item.rate
        })),
        suppiler: formData.supplier ,
        order_no: formData.orderNo,
        size: formData.size ,
        fabricator: formData.fabricator,
        style_no: formData.styleNo ,
        style_name: formData.styleName,
        remarks: formData.remarks,
        dated: formData.dated,
        delivery_date: formData.deliveryDate,
        delivery_at: formData.deliveryAt,
        issue_quantity: formData.issueQuantity,
        total: formData.total || "00",
        active: true
    };
};

