import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../../context/SnackBarContext'
import { InvoiceBill } from './InvoiceBill';
import fetcher from '../../../helper/fetcher';
import { EditInvoiceForm } from './EditInvoiceForm';
import EditInvoiceTable from './EditInvoiceTable';

const EditInvoice = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const loac = location.state?.data
    console.log("location.state.data =======>>", location.state?.data)

    const { showSuccess, showError } = useSnackbar();
    const [state, setState] = useState({
        isModalOpen: false,
        selectedContact: null,
        isDrawerOpen: false,
        isContactDrawerOpen: false,
        isProductDrawerOpen: false,
        availableUser: [],
        isLoading: false,
        searchQuery: '',
    })
    const [modalOpen, setModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        paymentStatus: "",
        trackingId: "",
        shippingDate: "",
        paymentMode: "",
        paymentDate: ""
    });
    const [tableData, setTableData] = useState([{
        id: 1,
        product: "",
        productCode: "",
        description: "",
        quantity: '',
        availableQty: '',
        UOM: "",
        price: '',
        discount: '',
        tax: "",
        amount: '',
    }]);

    const [billingData, setBillingData] = useState({
        subTotal: "0.00",
        totalDiscount: '0.00',
        beforeTax: '0.00',
        tax: '0.00',
        tcs: '0.00',
        roundOff: '0',
        total: '0.00',
    })


    const updateState = (data) => {
        setState((prevState) => {
            const newState = { ...prevState, ...data }
            return newState
        })
    }

    useEffect(() => {
        if (loac) {
            setFormData({
                shippingDate: loac.shippingDate ? loac.shippingDate.split("T")[0] : "",
                paymentDate: loac.paymentDate ? loac.paymentDate.split("T")[0] : "",
                paymentStatus: loac.paymentStatus || "",
                paymentMode: loac.paymentMode || '',
                trackingId: loac.trackingId || "",
            });

            setTableData(loac.product ? JSON.parse(loac.product) : []);

            setBillingData({
                subTotal: loac.subTotal || "0.00",
                totalDiscount: loac.totalDiscount || "0.00",
                beforeTax: loac.beforeTax || "0.00",
                tax: loac.tax || "0.00",
                tcs: loac.tcs || "0.00",
                roundOff: loac.roundOff || "0",
                total: loac.total || "0.00",
            });
        }
    }, []);

    // console.log("state.selectedContact ===>>", state.selectedContact?.customerName)

    const handleSaveInvoice = (e) => {
        updateState({ isLoading: true });
        e.preventDefault();

        const invoiceData = {
            shippingDate: formData.shippingDate,
            paymentStatus: formData.paymentStatus,
            trackingId: formData.trackingId,
            paymentDate: formData.paymentDate,
            paymentMode: formData.paymentMode,

            total: billingData.total,
            subTotal: billingData.subTotal,
            totalDiscount: billingData.totalDiscount,
            tax: billingData.tax,
            product: JSON.stringify(tableData),
        };

        if (!billingData.subTotal) {
            return showError("Subtotal is Required");
        }

        console.log("invoiceData ====>>", invoiceData);
        fetcher.patch(`inventory/invoice/update_invoice_by_id/${loac?._id}`, invoiceData)
            .then((res) => {
                showSuccess("Invoice Updated successfully");
                setTimeout(() => {
                    navigate("/inventory/manage-invoice");
                }, 1000);
            })
            .catch((error) => {
                showError(error.response.data?.message);
            })
            .finally(() => {
                updateState({ isLoading: false });
                setTimeout(() => {
                    setModalOpen(false);
                }, 500);
            });
    };

    // //console.log("formData ===", formData, "tableData ===>>", tableData)
    //console.log("billingData ====>>>", billingData,)

    return (
        <div className={`bg-white rounded-lg pb-12 ${["Paid", "PAID"].includes(loac.paymentStatus) && "h-screen"}`}>
            <EditInvoiceForm
                loac={loac}
                state={state}
                updateFunc={updateState}
                formData={formData}
                handleSaveInvoice={handleSaveInvoice}
                setFormData={setFormData}
                billingData={billingData}
                setModalOpen={setModalOpen}
                tableData={tableData}
                modalOpen={modalOpen}
            />

            {!["Paid", "PAID"].includes(loac.paymentStatus) && (
                <>
                    <EditInvoiceTable
                        loac={loac}
                        setBillingData={setBillingData}
                        state={state}
                        setTableData={setTableData}
                        updateFunc={updateState}
                        tableData={tableData}
                    />
                    <InvoiceBill
                        state={state}
                        updateFunc={updateState}
                        billingData={billingData}
                    />
                </>
            )}
        </div>
    )
}

export default EditInvoice
