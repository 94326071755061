import React, { useState } from 'react'
import { useSnackbar } from '../../../context/SnackBarContext'
import { formatPuchaseOrder } from '../../../utils/utils';
import { useNavigate } from 'react-router-dom';
import { PurchaseOrderForm } from '../../../features/Inventory/PuchaseOrderUI/PurchaseOrderForm';
import TablePurchaseOrder from '../../../features/Inventory/PuchaseOrderUI/TablePurchaseOrder';
import fetcher from '../../../helper/fetcher';

const PurchaseOrder = () => {
    const navigate = useNavigate()
    const { showSuccess, showError } = useSnackbar();
    const [state, setState] = useState({
        isModalOpen: false,
        selectedContact: null,
        isDrawerOpen: false,
        isContactDrawerOpen: false,
        isProductDrawerOpen: false,
        availableUser: [],
        isLoading: false,
        searchQuery: '',
        memo: '',
        attachment: null,
    })
    const [modalOpen, setModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        supplier: "",
        size: "",
        fabricator: "",
        styleNo: "",
        styleName: "",
        remarks: "",
        orderNo: "",
        dated: "",
        deliveryDate: "",
        deliveryAt: "",
        issueQuantity: "",
        total: "",
    });
    const [tableData, setTableData] = useState([{
        id: 0,
        productId: "",
        item: [{
            item_name: "",
            avg: "",
            colQty: "",
            quantity: '',
            unit: '',
            rate: "",
        }],
    }]);

    const updateState = (data) => {
        setState((prevState) => {
            const newState = { ...prevState, ...data }
            return newState
        })
    }

    const handleSaveInvoice = () => {
        updateState({ isLoading: true })
        const payLoadData = formatPuchaseOrder(formData, tableData)
        fetcher.post('/inventory/purchase/create_purchase', payLoadData)
            .then((response) => {
                showSuccess(response.data.message)
                setTimeout(() => {
                    navigate("/inventory/manage-purchase-order")
                }, 2000)
            })
            .catch((err) => {
                showError(err.response?.data?.message || "Failed to fetch job data");
            }).finally(() => {
                updateState({ isLoading: false })
            })
    }


    console.log("formData ===", formData, "tableData ===>>", tableData, "producttttttttt")

    return (
        <div className='bg-white rounded-lg pb-12 min-h-screen'>
            <PurchaseOrderForm
                state={state}
                updateFunc={updateState}
                formData={formData}
                handleSaveInvoice={handleSaveInvoice}
                setFormData={setFormData}
                setModalOpen={setModalOpen}
                tableData={tableData}
                modalOpen={modalOpen}
            />
            <TablePurchaseOrder
                state={state}
                setTableData={setTableData}
                updateFunc={updateState}
                tableData={tableData}
            />
        </div>
    )
}

export default PurchaseOrder
