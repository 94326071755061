import React from 'react';

export const InvoiceBill = ({ billingData }) => {
  return (
    <div className="flex lg:flex-row flex-col justify-end">
      {/* Summary Section */}
      <div className="text-gray-600 max-w-[900px]">
        <div className="flex justify-between mb-2">
          <span className="mr-24 font-bold text-gray-800 md:text-[16px] text-[14px]">Sub-total *</span>
          <span className='md:text-[16px] text-[14px]'>₹ {billingData.subTotal}</span>
        </div>
        <div className="flex justify-between gap-9 mb-2">
          <span className="mr-24 font-bold text-gray-800 md:text-[16px] text-[14px]">Total Discount (-)</span>
          <span className='md:text-[16px] text-[14px]' >₹ {billingData.totalDiscount}</span>
        </div>
        <div className="flex justify-between gap-9 mb-2 ">
          <span className="mr-24 font-bold text-gray-800  md:text-[16px] text-[14px]">Before-tax</span>
          <span className='md:text-[16px] text-[14px]'>₹ {billingData.beforeTax}</span>
        </div>
        <button className="hover:underline font-bold md:text-[16px] text-[14px]">+ Add Charges/Discount</button>
        <div className="flex justify-between mt-2 mb-2">
          <span className="mr-24 font-bold text-gray-800 md:text-[16px] text-[14px]">Tax (+)</span>
          <span className='md:text-[16px] text-[14px]'>₹ {billingData.tax}</span>
        </div>
        <div className="flex justify-between mb-2">
          <span className="mr-24 font-bold text-gray-800 md:text-[16px] text-[14px]">TCS</span>
          <span className='md:text-[16px] text-[14px]'>₹ {billingData.tcs}</span>
        </div>
        <div className="flex justify-between">
          <span className="mr-24 font-bold text-gray-800 md:text-[16px] text-[14px]">Rounding Off</span>
          <span className='md:text-[16px] text-[14px]'>{billingData.roundOff}</span>
        </div>
        <div className="flex justify-between border-t-2 mt-2">
          <span className="mr-24 font-bold text-gray-800 md:text-[20px] text-[16px] mt-3">Total *</span>
          <span className='font-bold text-gray-800 md:text-[20px] text-[16px] mt-3'>₹ {billingData.total}</span>
        </div>
      </div>
    </div>
  );
};