import Job from './pages/Jinrai/Job';
import Home from './pages/Jinrai/Home';
import About from './pages/Jinrai/About';
import Contact from './pages/Jinrai/Contact';
import Invoice from './pages/Inventory/Invoice';
import Portfolio from './pages/Jinrai/Portfolio';
import NotFound from './component/notFound/NotFound';
import JobApplyForm from './pages/Jinrai/JobApplyForm';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import InventoryLogin from './features/Inventory/auth/InventoryLogin';
import UserManagement from './pages/Inventory/Customer';
import { ManageInvoice } from './pages/Inventory/ManageInvoice';
import RoleProtectedRoute from './pages/Inventory/RoleProtectedRoute';
import ManageProduct from './pages/Inventory/ManageProduct';
import { ViewInvoice } from './pages/Inventory/ViewInvoice';
import EditInvoice from './pages/Inventory/EditInvoice/EditInvoice';
import ManageCustomer from './pages/Inventory/ManageCustomer/ManageCustomer';
import PurchaseOrder from './pages/Inventory/PurchaseOrder/PurchaseOrder';
import { ManagePurchaseOrder } from './pages/Inventory/PurchaseOrder/ManagePurchaseOrder';
import ViewProduct from './pages/Inventory/PurchaseOrder/ViewProduct';
import ManageItem from './pages/Inventory/PurchaseOrder/ManageItem';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/portfolio' element={<Portfolio />} />
        <Route path='/job' element={<Job />} />
        <Route path='/job-apply' element={<JobApplyForm />} />
        <Route path='/inventory/login' element={<InventoryLogin />} />
        <Route path='/inventory/create-invoice' element={
          <RoleProtectedRoute
            allowedRoles={['admin', "salesPerson", "subAdmin"]}
          >
            <Invoice />
          </RoleProtectedRoute>
        } />
        <Route path='/inventory/view-invoice' element={
          <RoleProtectedRoute
            allowedRoles={['admin', "salesPerson", "subAdmin"]}
          >
            <ViewInvoice />
          </RoleProtectedRoute>
        } />
        <Route path='/inventory/users' element={
          <RoleProtectedRoute
            allowedRoles={['admin', "salesPerson", "subAdmin"]}
          >
            <UserManagement />
          </RoleProtectedRoute>
        } />
        <Route path='/inventory/manage-invoice' element={
          <RoleProtectedRoute
            allowedRoles={['admin', "salesPerson", "subAdmin"]}
          >
            <ManageInvoice />
          </RoleProtectedRoute>
        } />
        <Route path='/inventory/manage-customer' element={
          <RoleProtectedRoute
            allowedRoles={['admin', "salesPerson", "subAdmin"]}
          >
            <ManageCustomer />
          </RoleProtectedRoute>
        } />

        <Route path='/inventory/manage-product' element={
          <RoleProtectedRoute
            allowedRoles={['admin', "salesPerson", "subAdmin"]}
          >
            <ManageProduct />
          </RoleProtectedRoute>
        } />
        <Route path='/inventory/purchase-order' element={
          <RoleProtectedRoute
            allowedRoles={['admin', "salesPerson", "subAdmin"]}
          >
            <PurchaseOrder />
          </RoleProtectedRoute>
        } />
        <Route path='/inventory/manage-purchase-order' element={
          <RoleProtectedRoute
            allowedRoles={['admin', "salesPerson", "subAdmin"]}
          >
            <ManagePurchaseOrder />
          </RoleProtectedRoute>
        } />
        <Route path='/inventory/view-product' element={
          <RoleProtectedRoute
            allowedRoles={['admin', "salesPerson", "subAdmin"]}
          >
            <ViewProduct />
          </RoleProtectedRoute>
        } />
        <Route path='/inventory/manage-purchase-item' element={
          <RoleProtectedRoute
            allowedRoles={['admin', "salesPerson", "subAdmin"]}
          >
            <ManageItem />
          </RoleProtectedRoute>
        } />
        <Route path='/inventory/edit-invoice' element={
          <RoleProtectedRoute
            allowedRoles={['admin', "salesPerson", "subAdmin"]}
          >
            <EditInvoice />
          </RoleProtectedRoute>
        } />

        {/* Admin Routes */}
        {/* <Route path='/admin/login' element={<Login />} />

        <Route path='/admin' element={<ProtectedRoute />}>
          <Route path='create-job' element={<Dashboard />} />
        </Route> */}

        {/* Fallback Route */}
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
