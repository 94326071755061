import React from 'react';
import WarningModal from '../../../component/WarningModal';
import MultipleSelect from '../../../component/MultipleSelect';

export const PurchaseOrderForm = ({
    state,
    setFormData,
    formData,
    handleSaveInvoice,
    setModalOpen,
    modalOpen,
}) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSaveButton = () => {
        setModalOpen(true)
    }

    const optionsSize = [
        { name: 'Small' },
        { name: 'Medium' },
        { name: 'Large' },
    ];

    const optionsStyle = [
        { name: 'Casual' },
        { name: 'Formal' },
        { name: 'Sporty' },
    ];

    const optionsDelivery = [
        { name: 'Home' },
        { name: 'Office' },
        { name: 'Pickup Point' },
    ];
    const optionsFabricator = [
        { name: 'Fabricator A' },
        { name: 'Fabricator B' },
        { name: 'Fabricator C' },
    ];

    return (
        <div >
            <div>
                <div className="flex justify-between items-center mb-4 bg-[#f4f5f7] pb-3 md:px-0 px-3">
                    <h2 className="md:text-[28px] text-[20px] font-bold text-gray-800">Purchase Order</h2>
                    <button
                        onClick={() => handleSaveButton()}
                        className="md:px-4  px-2 md:py-2 py-1  bg-gray-800 text-white border-gray-400 border rounded hover:bg-black">
                        Save
                    </button>
                </div>
                <div className="md:px-5 px-2">
                    <div className="flex md:flex-row flex-col justify-between pt-3">
                        <div>
                            <div>
                                <div >
                                    <p className="font-bold text-gray-800 text-[16px]">Buyer : AMBER SILK MILLS</p>

                                </div>
                            </div>

                        </div>

                        <div className='flex justify-end mr-15'>
                            <div className="flex flex-row justify-center items-center mb-4">
                                <p className="text-gray-700 mr-2">Total :</p>

                                <span className="text-gray-700 text-[24px] font-bold ">₹{formData.total || "0.00"}</span>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="grid md:grid-cols-6  flex-wrap gap-4 mb-6 max-w-[1000px] mt-5">
                            <div>
                                <label className="block text-sm font-semibold text-gray-700 mb-2 uppercase">Supplier</label>
                                <input
                                    type="text"
                                    name="supplier"
                                    value={formData.supplier}
                                    onChange={handleChange}
                                    className="w-full border p-2 rounded focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Supplier"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-bold text-gray-700 mb-2">Size </label>
                                <MultipleSelect
                                    label="Size"
                                    options={optionsSize}
                                    value={formData.size}
                                    onChange={handleChange}
                                    name="size"
                                    placeholder="Size"

                                />
                            </div>
                            <div>
                                <label className="block text-sm font-semibold text-gray-700 mb-2 uppercase">Fabricator</label>

                                <MultipleSelect
                                    label="Fabricator"
                                    options={optionsFabricator}
                                    value={formData.fabricator}
                                    onChange={handleChange}
                                    name="fabricator"
                                    placeholder="Fabricator"
                                />

                            </div>
                            <div>
                                <label className="block text-sm font-semibold text-gray-700 mb-2 uppercase">STYLE NO</label>
                                <input
                                    type="text"
                                    name="styleNo"
                                    value={formData.styleNo}
                                    onChange={handleChange}
                                    className="w-full border p-2 rounded focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Style No"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-semibold text-gray-700 mb-2 uppercase">Style Name</label>

                                <MultipleSelect
                                    label="Style Name"
                                    options={optionsStyle}
                                    value={formData.styleName}
                                    onChange={handleChange}
                                    name="styleName"
                                    placeholder="Style Name"
                                />
                            </div>


                            <div>
                                <label className="block text-sm font-semibold text-gray-700 mb-2">REMARKS</label>
                                <input
                                    type="text"
                                    name="remarks"
                                    value={formData.remarks}
                                    onChange={handleChange}
                                    className="w-full border p-2 rounded focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Remarks"
                                />
                            </div>
                        </div>

                        <div className="grid md:grid-cols-5  flex-wrap gap-4 mb-6 max-w-[1000px] mt-4">

                            <div>
                                <label className="block text-sm font-semibold text-gray-700 mb-2">ORDER NO</label>
                                <input
                                    type="text"
                                    name="orderNo"
                                    value={formData.orderNo}
                                    onChange={handleChange}
                                    className="w-full border p-2 rounded focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Order No"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-bold text-gray-700 mb-2">DATED *</label>
                                <input
                                    type="date"
                                    name="dated"
                                    value={formData.dated}
                                    onChange={handleChange}
                                    className="w-full border p-2 rounded focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Select Date"
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-bold text-gray-700 mb-2">DELIVERY DATE *</label>
                                <input
                                    type="date"
                                    name="deliveryDate"
                                    value={formData.deliveryDate}
                                    onChange={handleChange}
                                    className="w-full border p-2 rounded focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Select Delivery Date"
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-semibold text-gray-700 mb-2 uppercase">Delivery At</label>
                                <MultipleSelect
                                    label="Delivery At"
                                    options={optionsDelivery}
                                    value={formData.deliveryAt}
                                    onChange={handleChange}
                                    name="deliveryAt"
                                    placeholder="Delivery At"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-semibold text-gray-700 mb-2">ISSUE QUANTITY</label>
                                <input
                                    type="number"
                                    name="issueQuantity"
                                    value={formData.issueQuantity}
                                    onChange={handleChange}
                                    className="w-full border p-2 rounded focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Issue Quantity"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <WarningModal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                modalTitle="Are you sure you want to save this Puchase Order?"
                onConfirm={handleSaveInvoice}
                onCancel={() => setModalOpen(false)}
                confirmText="Yes, Confirm"
                cancelText="Cancel"
                isLoading={state.isLoading}
                color={false}
            />
        </div>
    );
};