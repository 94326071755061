import React from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import InvoicePdf from '../../features/Inventory/ManageInvoiceUI/InvoicePdf'
import { useLocation } from 'react-router-dom'

export const ViewInvoice = () => {
    const location = useLocation()
    return (
        <div className=' w-full h-[900px]'>
            <PDFViewer width="100%" height="100%">
                <InvoicePdf data={location.state.data} />
            </PDFViewer>
        </div>
    )
}
