import React from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import { useLocation } from 'react-router-dom'
import ProductPdf from '../../../features/Inventory/PuchaseOrderUI/ManageProduct/ProductPdf'

const ViewProduct = () => {
    const location = useLocation()
    return (
        <div className=' w-full h-[900px]'>
            <PDFViewer width="100%" height="100%">
                <ProductPdf data={location.state.data} />
            </PDFViewer>
        </div>
    )
}


export default ViewProduct
