// import React, { useState } from "react";
// import LongMenu from "../../../component/LongMenu";
// import DeleteIcon from "@mui/icons-material/Delete";
// import InvoiceTable from "../../../component/InvoiceTable";
// import { CustomSearch } from "../../../component/CustomSearch";
// import { useGetProduct } from "../../../hooks/Invoice";
// import { SideDrawer } from "../../../component/SideDrawer/SideDrawer";
// import InvoiceInput from "../../../component/InventoryUI/InvoiceInput";
// import { useSnackbar } from "../../../context/SnackBarContext";
// import ProductDetails from "../InvoiceUI/ProductDetails";
// import { useGetPurchaseItems } from "../../../hooks/purchaseOrder";

// const TablePurchaseOrder = ({ state, updateFunc, setTableData, tableData }) => {
//   console.log("tableData inside -====>>", tableData)
//   const { product: productsName, params, setParams } = useGetPurchaseItems()
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const { showError } = useSnackbar();
//   const [row, setRow] = useState(0)

//   const toggleDrawer = () => {
//     setDrawerOpen(!drawerOpen);
//   };

//   const menuOptions = [
//     { id: 1, title: "Delete", icon: <DeleteIcon />, color: "red" },
//   ];

//   const handleOptions = (rowId) => {
//     setTableData((prevData) => {
//       if (prevData.length > 1) {
//         return prevData.filter((item) => item.id !== rowId);
//       } else {
//         return prevData;
//       }
//     });
//   };

//   const handleInputChange = (id, field, value) => {
//     setTableData((prevData) => {
//       const updatedData = prevData.map((item) => {
//         if (item.id === id) {
//           const updatedItem = { ...item, [field]: value };
//           return updatedItem;
//         }
//         return item;
//       });
//       return updatedData;
//     });
//   };

//   const transformedData = tableData[0]?.item?.map((item, index) => ({
//     item: {
//       value: (<div
//         onClick={() => { updateFunc({ isProductDrawerOpen: true }); setRow(index) }}
//         className={`cursor-pointer bg-red-100`}
//         style={{
//           height: "100%",
//           minHeight: "1.5rem",
//         }}>
//         {item.item_name && <span>{item.item_name}</span>}
//       </div>),
//       type: "custom",
//     },

//     avg: {
//       value: (
//         <InvoiceInput
//           value={item.avg}
//           placeholder="0.00"
//           onChange={(e) => handleInputChange(item.id, "avg", e.target.value)}
//         />
//       ),
//       type: "custom",
//     },
//     colQty: {
//       value: (
//         <InvoiceInput
//           value={item.colQty}
//           placeholder="0.00"
//           onChange={(e) => handleInputChange(item.id, "colQty", e.target.value)}
//         />
//       ),
//       type: "custom",
//     },
//     quantity: {
//       value: (
//         <InvoiceInput
//           value={item.quantity}
//           placeholder="0.00"
//           onChange={(e) => handleInputChange(item.id, "quantity", e.target.value)}
//         />
//       ),
//       type: "custom",
//     },
//     unit: {
//       value: (
//         <InvoiceInput
//           value={item.unit}
//           icon={false}
//           placeholder="0.00"
//           onChange={(e) => handleInputChange(item.id, "unit", e.target.value)}
//         />
//       ),
//       type: "custom",
//     },

//     rate: {
//       value: (
//         <InvoiceInput
//           value={item.rate}
//           icon={true}
//           placeholder="0.00"
//           onChange={(e) => handleInputChange(item.id, "rate", e.target.value)}
//         />
//       ),
//       type: "custom",
//     },
//     Action: {
//       value: (
//         <LongMenu
//           options={menuOptions}
//           handleOptions={() => handleOptions(item.id)}
//         />
//       ),
//       type: "custom",
//     },
//   }));

//   const columns = [
//     "Item *",
//     "Avg (1 Pcs) *",
//     "1 Col. Qty",
//     "Quantity",
//     "Unit *",
//     "Rate",
//     "Action",
//   ];

//   const handleAddItem = () => {
//     const newRow = {
//       id: tableData.length + 1,
//       productId: "",
//       item: [{
//         avg: "",
//         colQty: "",
//         quantity: '',
//         unit: '',
//         rate: "",
//       }],
//     };
//     setTableData((prevData) => [...prevData, newRow]);
//   };

//   const handleClearAllItems = () => {
//     setTableData([]);
//   };

//   const handleSelectedProduct = (seletedItem) => {
//     console.log("itemm", seletedItem)
//     setTableData((prevData) => {
//       const isProductCodeExists = prevData.some(
//         (rowData) => rowData.item == seletedItem.styleName
//       );
//       if (isProductCodeExists) {
//         showError("This Product Already Selected Plz Select Other")
//         return prevData;
//       }
//       const result = prevData.map((rowData, index) =>
//         index === row
//           ? {
//             ...rowData,
//             productId: seletedItem._id || '',
//             styleName: seletedItem.styleName || '',
//             item: seletedItem.item.map((items) => ({
//               item_name: items.item_name || "",
//               avg: items.avg || "",
//               colQty: items.colQty || "",
//               quantity: items.quantity || "",
//               unit: items.unit || "",
//               rate: items.rate || "",
//             })),
//           }
//           : rowData

//       )
//       return result
//     })
//     updateFunc({ isProductDrawerOpen: false });
//   };

//   const handleAddProduct = () => {
//     updateFunc({ isProductDrawerOpen: false })
//     toggleDrawer()
//   }

//   return (
//     <div className="md:px-5 px-2">
//       <div className="">
//         <InvoiceTable
//           data={transformedData}
//           columns={columns}
//           checkbox={false}
//           defaultRowSelected={[0]}
//         />
//       </div>
//       <div className="flex md:flex-row flex-wrap items-center justify-between space-x-4 mb-4 mt-4">
//         {/* Top Section */}
//         <div className="flex md:flex-row flex-wrap items-center space-x-4 mb-4">
//           <button
//             className="text-[#db3647] font-medium hover:underline"
//             onClick={handleAddItem}
//           >
//             + Add Item
//           </button>
//           <button
//             className="text-red-600 font-medium hover:underline"
//             onClick={handleClearAllItems}
//           >
//             - Clear all items
//           </button>
//         </div>
//         {/* Checkbox */}

//       </div>

//       <SideDrawer open={state.isProductDrawerOpen}
//         onClose={() => updateFunc({ isProductDrawerOpen: false })}
//         title={"Add Product"}
//         headerHidden={true}
//         customWidth={false} >
//         <div className='px-4'>
//           <CustomSearch
//             value={params.query}
//             onChange={(e) => setParams((pev) => ({ ...pev, query: e.target.value }))}
//           />
//           <div className='pt-4 max-h-[80vh]  overflow-y-auto'>
//             {productsName.results?.length > 0 && productsName.results?.map((item, index) => {
//               return (
//                 <p key={index} onClick={() => handleSelectedProduct(item)}
//                   className='cursor-pointer hover:text-[#db3647] p-1' >{item.styleName}</p>
//               )
//             })}
//           </div>

//           <div className="flex justify-between items-center">
//             <button
//               onClick={handleAddProduct}
//               className="bg-[#db3647] text-white px-4 py-2 rounded"
//             >
//               +Add New
//             </button>
//           </div>
//         </div>
//       </SideDrawer>

//       <SideDrawer
//         open={drawerOpen}
//         onClose={toggleDrawer}
//         title="Product Details"
//         headerHidden={true}
//         customWidth={true}
//       >
//         <div style={{ padding: "20px" }}>
//           <ProductDetails setDrawerOpen={setDrawerOpen} />
//         </div>
//       </SideDrawer>
//     </div>
//   );
// };

// export default TablePurchaseOrder;

import React, { useState } from "react";
import LongMenu from "../../../component/LongMenu";
import DeleteIcon from "@mui/icons-material/Delete";
import InvoiceTable from "../../../component/InvoiceTable";
import { CustomSearch } from "../../../component/CustomSearch";
import { SideDrawer } from "../../../component/SideDrawer/SideDrawer";
import InvoiceInput from "../../../component/InventoryUI/InvoiceInput";
import { useSnackbar } from "../../../context/SnackBarContext";
import ProductDetails from "../InvoiceUI/ProductDetails";
import { useGetPurchaseItems } from "../../../hooks/purchaseOrder";

const TablePurchaseOrder = ({ state, updateFunc, setTableData, tableData }) => {
  console.log("tableData inside -====>>", tableData);

  const { product: productsName, params, setParams } = useGetPurchaseItems();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { showError } = useSnackbar();
  const [selectedRow, setSelectedRow] = useState(null);

  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  const menuOptions = [{ id: 1, title: "Delete", icon: <DeleteIcon />, color: "red" }];

  const handleOptions = (rowId) => {
    setTableData((prevData) =>
      prevData.length > 1 ? prevData.filter((item) => item.id !== rowId) : prevData
    );
  };

  const handleInputChange = (id, field, value) => {
    setTableData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  const transformedData = tableData.flatMap((rowData, rowIndex) =>
    rowData.item.map((item, index) => ({
      item: {
        value: (
          <div
            onClick={() => {
              updateFunc({ isProductDrawerOpen: true });
              setSelectedRow(rowIndex);
            }}
            className="cursor-pointer bg-red-100"
            style={{ height: "100%", minHeight: "1.5rem" }}
          >
            {item.item_name && <span>{item.item_name}</span>}
          </div>
        ),
        type: "custom",
      },
      avg: {
        value: (
          <InvoiceInput
            value={item.avg}
            placeholder="0.00"
            onChange={(e) => handleInputChange(rowData.id, "avg", e.target.value)}
          />
        ),
        type: "custom",
      },
      colQty: {
        value: (
          <InvoiceInput
            value={item.colQty}
            placeholder="0.00"
            onChange={(e) => handleInputChange(rowData.id, "colQty", e.target.value)}
          />
        ),
        type: "custom",
      },
      quantity: {
        value: (
          <InvoiceInput
            value={item.quantity}
            placeholder="0.00"
            onChange={(e) => handleInputChange(rowData.id, "quantity", e.target.value)}
          />
        ),
        type: "custom",
      },
      unit: {
        value: (
          <InvoiceInput
            value={item.unit}
            icon={false}
            placeholder="0.00"
            onChange={(e) => handleInputChange(rowData.id, "unit", e.target.value)}
          />
        ),
        type: "custom",
      },
      rate: {
        value: (
          <InvoiceInput
            value={item.rate}
            icon={true}
            placeholder="0.00"
            onChange={(e) => handleInputChange(rowData.id, "rate", e.target.value)}
          />
        ),
        type: "custom",
      },
      Action: {
        value: <LongMenu options={menuOptions} handleOptions={() => handleOptions(rowData.id)} />,
        type: "custom",
      },
    }))
  );

  const columns = ["Item *", "Avg (1 Pcs) *", "1 Col. Qty", "Quantity", "Unit *", "Rate", "Action"];

  const handleAddItem = () => {
    setTableData((prevData) => [
      ...prevData,
      {
        id: prevData.length + 1,
        productId: "",
        item: [{ item_name: "", avg: "", colQty: "", quantity: "", unit: "", rate: "" }],
      },
    ]);
  };

  const handleClearAllItems = () => setTableData([]);

  const handleSelectedProduct = (selectedItem) => {
    console.log("itemm", selectedItem);

    setTableData((prevData) => {
      const isProductExists = prevData.some((rowData) =>
        rowData.item.some((itm) => itm.item_name === selectedItem.styleName)
      );

      if (isProductExists) {
        showError("This Product Already Selected. Please Select Another.");
        return prevData;
      }

      return prevData.map((rowData, index) =>
        index === selectedRow
          ? {
            ...rowData,
            productId: selectedItem._id || "",
            item: selectedItem.item.map((itm) => ({
              item_name: itm.item_name || "",
              avg: itm.avg || "",
              colQty: itm.colQty || "",
              quantity: itm.quantity || "",
              unit: itm.unit || "",
              rate: itm.rate || "",
            })),
          }
          : rowData
      );
    });

    updateFunc({ isProductDrawerOpen: false });
  };

  const handleAddProduct = () => {
    updateFunc({ isProductDrawerOpen: false });
    toggleDrawer();
  };

  return (
    <div className="md:px-5 px-2">
      <InvoiceTable data={transformedData} columns={columns} checkbox={false} defaultRowSelected={[0]} />

      <div className="flex md:flex-row flex-wrap items-center justify-between space-x-4 mb-4 mt-4">
        <div className="flex md:flex-row flex-wrap items-center space-x-4 mb-4">
          <button className="text-[#db3647] font-medium hover:underline" onClick={handleAddItem}>
            + Add Item
          </button>
          <button className="text-red-600 font-medium hover:underline" onClick={handleClearAllItems}>
            - Clear all items
          </button>
        </div>
      </div>

      <SideDrawer
        open={state.isProductDrawerOpen}
        onClose={() => updateFunc({ isProductDrawerOpen: false })}
        title="Add Product"
        headerHidden
      >
        <div className="px-4">
          <CustomSearch value={params.query} onChange={(e) => setParams((prev) => ({ ...prev, query: e.target.value }))} />
          <div className="pt-4 max-h-[80vh] overflow-y-auto">
            {productsName.results?.length > 0 &&
              productsName.results.map((item, index) => (
                <p key={index} onClick={() => handleSelectedProduct(item)} className="cursor-pointer hover:text-[#db3647] p-1">
                  {item.styleName}
                </p>
              ))}
          </div>
          <div className="flex justify-between items-center">
            <button onClick={handleAddProduct} className="bg-[#db3647] text-white px-4 py-2 rounded">
              +Add New
            </button>
          </div>
        </div>
      </SideDrawer>

      <SideDrawer open={drawerOpen} onClose={toggleDrawer} title="Product Details" headerHidden customWidth>
        <div style={{ padding: "20px" }}>
          <ProductDetails setDrawerOpen={setDrawerOpen} />
        </div>
      </SideDrawer>
    </div>
  );
};

export default TablePurchaseOrder;
