import fetcher from '../../../helper/fetcher'
import React, { useEffect, useRef, useState } from 'react'
import { useSnackbar } from '../../../context/SnackBarContext'
import Search from "../../../component/Search";
import AddItems from '../../../features/Inventory/PuchaseOrderUI/ManageItem/AddItems';
import { SideDrawer } from "../../../component/SideDrawer/SideDrawer";
import CollapsibleTable from "../../../component/CollapseTable";
import PaginationFeature from "../../../component/PaginationFeature/PaginationFeature"

const ManageItem = () => {
    const { showSuccess, showError } = useSnackbar();
    const [refetch, setRefetch] = useState(1)
    const [state, setState] = useState({
        query: "",
        isModalOpen: false,
        isLoading: false,
        id: null
    })
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [edit, setEdit] = useState({})
    const isFirstRender = useRef(true);

    const [data, setData] = useState({
        results: [],
        page: 0,
        limit: 0,
        totalPages: 0,
        totalResults: 0,
    })
    const [params, setParams] = useState({
        page: 1,
        limit: 20,
    })

    const setPage = (page) => {
        setParams({ ...params, page: page });
    };

    const updateState = (data) => {
        setState((prevState) => {
            const newState = { ...prevState, ...data }
            return newState
        })
    }

    const handleGetAllItems = (val) => {
        fetcher.get(`/inventory/purchase_item/getAll_purchase_item?query=${state.query}&page=${val ?? params.page}&limit=${params.limit}`)
            .then((res) => {
                setData({ ...res.data });
            })
            .catch((error) => {
                console.log("error?.res?.data?.mgs ===>>", error?.res?.data?.mgs);
            })
            .finally(() => {
                updateState({ isLoading: false });
            });
    };

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        handleGetAllItems();
    }, [refetch, params]);

    const handleDeleteItems = () => {
        if (!state.id) return;
        updateState({ isLoading: true })
        fetcher.delete(`inventory/purchase_item/delete_purchase_item/${state.id}`)
            .then((res) => {
                showSuccess("Deleted successfully")
            })
            .catch((error) => {
                showError("Some Error Occured");
            }).finally(() => {
                setRefetch((pre) => pre + 1)
                updateState({ isLoading: false, isModalOpen: false })
            })
    }

    console.log("dagssgf ===>>", state)

    return (
        <div>
            {/* Header */}
            <div className="flex md:flex-row flex-col justify-between gap-3 mb-6 md:px-0 px-5 md:py-0 py-1">
                <h1 className="md:text-[26px] text-[20px] font-bold text-gray-800">Manage Purchase Items</h1>
                <button
                    className="bg-gray-800 text-white px-5 py-2 rounded hover:bg-[#191919]"
                    onClick={() => setIsDrawerOpen(true)}
                >
                    + Add Items
                </button>
            </div>

            <div className="bg-white shadow rounded-md md:p-6 p-2 min-h-screen">
                <div className="flex md:flex-row flex-wrap  justify-between items-center mb-5">
                    <h2 className=" font-bold text-[#db3647] ">
                        All Purchase Items
                    </h2>
                    <div className="mt-4 flex flex-row justify-between items-center gap-4">
                        <Search
                            handleSeachButton={() => handleGetAllItems(1)}
                            onChange={(e) => updateState({ query: e.target.value })}
                            value={state.query}
                            placeholder="Search"
                        />
                    </div>
                </div>

                <CollapsibleTable
                    setEdit={setEdit}
                    handleDeleteItems={handleDeleteItems}
                    tableData={data.results || []}
                    updatefun={updateState}
                    state={state}
                />

                <PaginationFeature
                    totalPage={data.totalPages}
                    setPage={setPage}
                />
            </div>

            <SideDrawer
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
                title="Add Purchase Items"
                headerHidden={true}
                customWidth={true}
            >
                <div style={{ padding: "20px" }}>
                    <AddItems setIsDrawerOpen={setIsDrawerOpen} setRefetch={setRefetch} />
                </div>
            </SideDrawer>
        </div>
    )
}

export default ManageItem

