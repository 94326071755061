import React, { useState } from "react";
import Search from "../../../component/Search";
import EditIcon from "@mui/icons-material/Edit";
import LongMenu from "../../../component/LongMenu";
import DeleteIcon from "@mui/icons-material/Delete";
import DynamicTable from "../../../component/DynamicTable";
import { SideDrawer } from "../../../component/SideDrawer/SideDrawer";
import PaginationFeature from "../../../component/PaginationFeature/PaginationFeature";
import AddNewContact from "../InvoiceUI/AddNewContact";
import EditCustomer from "./EditCustomer";
import { apiUrl, getAuthToken } from "../../../utils/utils";
import axios from "axios";
import { FaFileExcel } from "react-icons/fa6";

const AllCustomer = ({
    data,
    updateFunc,
    handleGetAllUsers,
    parentState,
    setPage
}) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false)
    const [edit, setEdit] = useState({})
    const handleRowSelect = (selectedIndexes) => {
        updateFunc({ selectedRows: selectedIndexes })
    };

    const handleOptions = (option, item) => {
        setEdit(item)
        updateFunc({ id: item._id })
        if (option.id == 1) {
            setIsEditDrawerOpen(true)
        } else if (option.id == 2) {
            updateFunc({ isModalOpen: true, id: item._id })
        }
    };

    const menuOptions = [
        { id: 1, title: "Edit", icon: <EditIcon /> },
        { id: 2, title: "Delete", icon: <DeleteIcon />, color: "red" }
    ];

    const columns = ["SR.NO.", "Customer Name", "Phone Number", "Email", "Actions"];
    const transformedData = data.results.map((item, index) => {
        return {
            SRNO: (data.page - 1) * 20 + (index + 1),
            customerName: item.customerName,
            phone: item.telephone,
            email: item.email || "N/A",
            actions: {
                value: <LongMenu
                    options={menuOptions}
                    handleOptions={(prev) => handleOptions(prev, item)}
                />
            }
        };
    });

    const token = getAuthToken()
    const handleDownload = async () => {
        try {
            const response = await axios.get(`${apiUrl}inventory/customer/csv_customer`, {
                responseType: 'blob',
                headers: {
                    'Accept': 'text/csv',
                    'Authorization': `${token}`
                }
            });

            // Create a download link
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
            const a = document.createElement('a');
            a.href = url;
            a.download = `customer.csv`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            console.error('Error downloading CSV:', error);
        }
    };


    return (
        <div >
            {/* Header */}
            <div className="flex md:flex-row flex-col justify-between gap-3 mb-6 md:px-0 px-5 md:py-0 py-1">
                <h1 className="md:text-[26px] text-[20px] font-bold text-gray-800">Manage Customer</h1>
                <button
                    className="bg-gray-800 text-white px-5 py-2 rounded hover:bg-[#191919]"
                    onClick={() => setIsDrawerOpen(true)}
                >
                    + Add Customer
                </button>
            </div>

            <div className="bg-white shadow rounded-md md:p-6 p-2 min-h-screen">
                <div className="flex md:flex-row flex-wrap  justify-between items-center mb-5">
                    <h2 className=" font-bold text-[#db3647] ">
                        All Customer
                    </h2>
                    <div className="mt-4 flex flex-row justify-between items-center gap-4">
                    <div
                            onClick={handleDownload}
                            className="text-[14px] cursor-pointer border-2 border-[#db3647] bg-[#db3647] text-white px-4 py-1 rounded-md flex items-center gap-1 hover:bg-[#b92c3b] transition-all shadow-md"
                        >
                            <FaFileExcel size={20} />Download</div>
                    
                        <Search
                            handleSeachButton={() => handleGetAllUsers(1)}
                            onChange={(e) => updateFunc({ query: e.target.value })}
                            value={parentState.query}
                            placeholder="Customer Name"

                        />
                    </div>
                </div>

                {/* Dynamic Table */}
                <DynamicTable
                    data={transformedData}
                    columns={columns}
                    defaultRowSelected={[]}
                    onRowSelect={handleRowSelect}
                    checkbox={false}
                />
                <PaginationFeature
                    totalPage={data.totalPages}
                    setPage={setPage}
                />
            </div>

            <SideDrawer
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
                title="Add Customer"
                headerHidden={true}
                customWidth={true}
            >
                <div style={{ padding: "20px" }}>
                    <AddNewContact
                        updateFunc={(val) => {
                            setIsDrawerOpen(val.isDrawerOpen);
                            handleGetAllUsers()
                        }}
                    />
                </div>
            </SideDrawer>

            <SideDrawer
                open={isEditDrawerOpen}
                onClose={() => setIsEditDrawerOpen(false)}
                title="Edit Customer"
                headerHidden={true}
                customWidth={true}
            >
                <div style={{ padding: "20px" }}>
                    <EditCustomer
                        edit={edit}
                        updateFunc={(val) => {
                            setIsEditDrawerOpen(val.isDrawerOpen);
                            handleGetAllUsers()
                        }}
                    />
                </div>
            </SideDrawer>
        </div>
    );
};

export default AllCustomer
