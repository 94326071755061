import React, { useState } from "react";
import moment from "moment";
import InvoicePdf from "./InvoicePdf";
import { GrView } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import Search from "../../../component/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { pdf } from "@react-pdf/renderer";
import DynamicTable from "../../../component/DynamicTable";
import WarningModal from "../../../component/WarningModal";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { apiUrl, getAuthToken } from "../../../utils/utils";
import axios from "axios";
import { FaFileExcel } from "react-icons/fa6";
import PaginationFeature from "../../../component/PaginationFeature/PaginationFeature";
import LongMenu from "../../../component/LongMenu";

export const ManageInvoiceUI = ({
  invoices,
  updateState,
  handleDeleteInvoice,
  isLoading,
  state,
  setPage,
  setRefetch,
  handleSearchInvoice,
}) => {
  const navigate = useNavigate()
  const [data, setData] = useState({})
  const columns = [
    "SR.NO.",
    "Number",
    "Customer Name",
    "Sales Person",
    "Invoice Date",
    "Payment Date",
    "Payment Status",
    "Total Amount",
    "Actions",
  ];

  const handleOptions = async (option, item) => {
    if (option.id == 1) {
      navigate("/inventory/view-invoice", { state: { data: item } })
    } else if (option.id == 2) {
      try {
        const blob = await pdf(<InvoicePdf data={item} />).toBlob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `Invoice_${item.invoiceNo}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    }
    else if (option.id == 3) {
      setData(item)
      navigate("/inventory/edit-invoice", { state: { data: item } })
    }
    else if (option.id == 4) {
      updateState({ id: item._id, isModalOpen: true })
    }
  };

  const menuOptions = [
    { id: 1, title: "View", icon: <GrView /> },
    { id: 2, title: "Download", icon: <FaCloudDownloadAlt /> },
    { id: 4, title: "Delete", icon: <DeleteIcon className="text-red-700" />, color: "red" },
    { id: 3, title: "Edit", icon: <ModeEditIcon /> }
  ];
  
  const transformedData = invoices.results?.map((item, index) => {
    return {
      SRNO: (invoices.page - 1) * 20 + (index + 1),
      Number: item.invoiceNo || "N/A",
      Contact: item.shipTo?.customerName || "N/A",
      salesPerson: item.salesPerson || "N/A",
      InvoiceDate: item.invoiceDate ? moment(item.invoiceDate).format("DD-MM-YYYY") : "N/A",
      paymentDate: item.paymentDate || "N/A",
      paymentStatus: item.paymentStatus || "N/A",
      TotalAmount: `₹${item.total}` || "N/A",
      actions: {
        value: (
          <LongMenu
            options={menuOptions}
            handleOptions={(prev) => handleOptions(prev, item)}
          />
        ),
      },
    };
  });


  const token = getAuthToken()
  const handleDownload = async () => {
    try {
      const response = await axios.get(`${apiUrl}inventory/invoice/csv_invoice`, {
        responseType: 'blob',
        headers: {
          'Accept': 'text/csv',
          'Authorization': `${token}`
        }
      });

      // Create a download link
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
      const a = document.createElement('a');
      a.href = url;
      a.download = `invoice.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  };

  return (
    <div>
      {/* Header */}
      <div className="flex md:flex-row flex-col md:justify-between justify-start  gap-3 mb-6 md:px-2 px-4 md:py-0 py-1">
        <h1 className="md:text-[28px] text-[20px] font-bold text-gray-800 ">
          Manage Invoice
        </h1>
        <div className=" flex sm:flex-row flex-col justify-between items-center gap-4">
          <div
            onClick={handleDownload}
            className="text-[14px] cursor-pointer border-2 border-[#db3647] bg-[#db3647] text-white px-4 py-1 rounded-md flex items-center gap-1 hover:bg-[#b92c3b] transition-all shadow-md"
          >
            <FaFileExcel size={20} />Download</div>

          <Search
            handleSeachButton={() => handleSearchInvoice(1)}
            onChange={(e) => updateState({ query: e.target.value })}
            value={state.query}
            placeholder="Name or Invoice No."
          />
        </div>
      </div>

      <div className="bg-white shadow rounded-md md:p-6 p-2 min-h-screen">
        {/* Dynamic Table */}
        <DynamicTable
          data={transformedData}
          columns={columns}
          defaultRowSelected={[]}
          checkbox={false}
        />
        <PaginationFeature
          totalPage={invoices.totalPages}
          setPage={setPage}
        />
      </div>

      <WarningModal
        isOpen={state.isModalOpen}
        onClose={() => updateState({ isModalOpen: false })}
        modalTitle="Are you sure you want to delete this item?"
        onConfirm={handleDeleteInvoice}
        onCancel={() => updateState({ isModalOpen: false })}
        confirmText={isLoading ? "Deleting..." : "Delete"}
        cancelText="Cancel"
        isLoading={isLoading}
      />
    </div>
  );
};

