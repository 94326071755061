import React from 'react';
import { useSnackbar } from '../../../context/SnackBarContext';
import WarningModal from '../../../component/WarningModal';
import MultipleSelect from '../../../component/MultipleSelect';

export const EditInvoiceForm = ({
  state,
  updateFunc,
  setFormData,
  formData,
  handleSaveInvoice,
  setModalOpen,
  modalOpen,
  tableData,
  loac,
  billingData }) => {
  const { showSuccess, showError } = useSnackbar();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const options = [{ name: "PAID" }, { name: "UNPAID" }];

  const validateTableData = () => {
    for (let row of tableData) {
      if (!row.product) {
        showError('Product is required for each row');
        return false;
      }
      if (!row.quantity || isNaN(row.quantity) || row.quantity <= 0) {
        showError('Quantity Required');
        return false;
      }
      if (!row.price || isNaN(row.price) || row.price <= 0) {
        showError('Price must be a valid number');
        return false;
      }
    }
    return true;
  };

  const validateBillingData = () => {
    if (!billingData.subTotal || parseFloat(billingData.subTotal) <= 0) {
      showError('Subtotal must be greater than 0');
      return false;
    }
    if (!billingData.total || parseFloat(billingData.total) <= 0) {
      showError('Total must be greater than 0');
      return false;
    }
    return true;
  };

  const handleSaveButton = () => {
    if (!validateTableData() || !validateBillingData()) {
      return;
    }
    setModalOpen(true)
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-4 bg-[#f4f5f7] pb-3 md:px-0 px-3">
        <h2 className="md:text-[28px] text-[20px] font-bold text-gray-800">Edit Invoice</h2>
        <button
          onClick={() => handleSaveButton()}
          className="md:px-4  px-2 md:py-2 py-1  bg-gray-800 text-white border-gray-400 border rounded hover:bg-black">
          Save
        </button>
      </div>

      <div className="md:px-5 px-2">

        {!["Paid", "PAID"].includes(loac.paymentStatus) && (
          <div className="flex-col justify-end">
            <p className="text-gray-700">Balance Due:</p>
            <span className="text-gray-700 text-[24px] font-bold ">₹{billingData.total || "0.00"}</span>
            <p className="text-gray-700 text-sm font-semibold mt-4">
              # No: <span className=' text-gray-500'>{loac?.invoiceNo} (Auto generated)</span>
            </p>
          </div>
        )}

        {/* Invoice Details */}
        <div className="grid lg:grid-cols-5 md:flex-wrap gap-4 mb-6 max-w-[1000px] mt-9">
          <div>
            <label className="block text-sm font-semibold text-gray-700 mb-2">SHIPPING / DATE</label>
            <input
              type="date"
              name="shippingDate"
              value={formData.shippingDate}
              onChange={handleChange}
              className="w-full border p-2 rounded focus:ring-blue-500 focus:border-blue-500"
              placeholder="Select Shipping Date"
            />
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-700 mb-2">PAYMENT MODE *</label>
            <input
              type="text"
              name="paymentMode"
              value={formData.paymentMode}
              onChange={handleChange}
              className="w-full border p-2 rounded focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter Payment Mode"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-700 mb-2">PAYMENT DATE*</label>
            <input
              type="date"
              name="paymentDate"
              value={formData.paymentDate}
              onChange={handleChange}
              className="w-full border p-2 rounded focus:ring-blue-500 focus:border-blue-500"
              placeholder="Select Payment Date"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-700 mb-2">PAYMENT STATUS</label>
            <MultipleSelect
              options={options}
              value={formData.paymentStatus}
              onChange={handleChange}
              label="PAYMENT STATUS"
              name={"paymentStatus"}
              placeholder="Select an option"
            />
          </div>

          <div>
            <label className="block text-sm font-semibold text-gray-700 mb-2">TRACKING ID</label>
            <input
              type="text"
              name="trackingId"
              value={formData.trackingId}
              onChange={handleChange}
              className="w-full border p-2 rounded focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter Tracking ID"
            />
          </div>
        </div>
      </div>

      <WarningModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        modalTitle="Are you sure you want to save this invoice?"
        onConfirm={handleSaveInvoice}
        onCancel={() => setModalOpen(false)}
        confirmText="Yes, Confirm"
        cancelText="Cancel"
        isLoading={state.isLoading}
        color={false}
      />
    </div>
  );
};