import React from "react";
import { Input } from "antd";
import Allstate from "../../../utils/state.json";
import { CircularProgress } from "@mui/material";
import MultipleSelect from "../../../component/MultipleSelect";

const ManageAddress = ({
    handleSaveAddress,
    handleAddressChange,
    address,
    isLoading,
    setAddresses
}) => {
    const handleToggleShippingAddress = () => {
        setAddresses((prevAddresses) => {
            const isShippingSame = !prevAddresses?.isShippingSameAsBilling;
            return {
                ...prevAddresses,
                isShippingSameAsBilling: isShippingSame,
                ...(isShippingSame && {
                    shippingAddress1: prevAddresses?.address1,
                    shippingAddress2: prevAddresses?.address2,
                    shippingCity: prevAddresses?.city,
                    shippingPostalCode: prevAddresses?.postalCode,
                    shippingState: prevAddresses?.state,
                    shippingCountry: prevAddresses?.country
                })
            };
        });
    };

    return (
        <div>
            {/* Billing Address */}
            <div className="bg-gray-100 px-3 py-5 rounded-[10px] border border-gray-300 mb-4">
                <div className="mb-4 flex flex-row justify-between">
                    <p className="font-bold text-gray-800 text-[16px]">Billing Address</p>
                </div>

                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label className="block text-sm text-gray-600 mb-1">Address 1 *</label>
                        <Input
                            value={address.address1}
                            onChange={(e) => handleAddressChange("address1", e.target.value)}
                            className="w-full rounded"
                            placeholder="Address"
                        />
                    </div>
                    <div>
                        <label className="block text-sm text-gray-600 mb-1">Address 2</label>
                        <Input
                            value={address.address2}
                            onChange={(e) => handleAddressChange("address2", e.target.value)}
                            className="w-full rounded"
                            placeholder="Address 2"
                        />
                    </div>
                    <div>
                        <label className="block text-sm text-gray-600 mb-1">City *</label>
                        <Input
                            value={address.city}
                            onChange={(e) => handleAddressChange("city", e.target.value)}
                            className="w-full rounded"
                            placeholder="City"
                        />
                    </div>
                    <div>
                        <label className="block text-sm text-gray-600 mb-1">Postal Code *</label>
                        <Input
                            value={address.postalCode}
                            onChange={(e) => handleAddressChange("postalCode", e.target.value)}
                            className="w-full rounded"
                            placeholder="Postal Code"
                        />
                    </div>
                    <div>
                        <label className="block text-sm text-gray-600 mb-1">State *</label>
                        <MultipleSelect
                            options={Allstate}
                            label="State"
                            value={address.state}
                            defaultValue={address.state}
                            onChange={(selected) => handleAddressChange("state", selected.target.value)}
                            name="state"
                        />
                    </div>
                    <div>
                        <label className="block text-sm text-gray-600 mb-1">Country</label>
                        <select
                            value={address.country}
                            onChange={(e) => handleAddressChange("country", e.target.value)}
                            className="border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5"
                        >
                            <option value="">Choose Country</option>
                            <option value="India">India</option>
                            <option value="Australia">Australia</option>
                        </select>
                    </div>
                </div>

                <div className="mt-3">
                    <input
                        type="checkbox"
                        id="shipping-checkbox"
                        name="shippingAddress"
                        className="mr-2"
                        checked={address.isShippingSameAsBilling || false}
                        onChange={handleToggleShippingAddress}
                    />
                    <label htmlFor="shipping-checkbox" className="text-sm">
                        Is Shipping Address same as Billing Address?
                    </label>
                </div>
            </div>

            {/* Shipping Address */}
            <div className="bg-gray-100 px-3 py-5 rounded-[10px] border border-gray-300 mb-4">
                <div className="mb-4 flex flex-row justify-between">
                    <p className="font-bold text-gray-800 text-[16px]">Shipping Address</p>
                </div>

                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label className="block text-sm text-gray-600 mb-1">Address 1 *</label>
                        <Input
                            value={address.isShippingSameAsBilling ? address.address1 : address.shippingAddress1}
                            onChange={(e) => handleAddressChange("shippingAddress1", e.target.value)}
                            className="w-full rounded"
                            placeholder="Address"
                            disabled={address.isShippingSameAsBilling}
                        />
                    </div>
                    <div>
                        <label className="block text-sm text-gray-600 mb-1">Address 2</label>
                        <Input
                            value={address.isShippingSameAsBilling ? address.address2 : address.shippingAddress2}
                            onChange={(e) => handleAddressChange("shippingAddress2", e.target.value)}
                            className="w-full rounded"
                            placeholder="Address 2"
                            disabled={address.isShippingSameAsBilling}
                        />
                    </div>
                    <div>
                        <label className="block text-sm text-gray-600 mb-1">City *</label>
                        <Input
                            value={address.isShippingSameAsBilling ? address.city : address.shippingCity}
                            onChange={(e) => handleAddressChange("shippingCity", e.target.value)}
                            className="w-full rounded"
                            placeholder="City"
                            disabled={address.isShippingSameAsBilling}
                        />
                    </div>
                    <div>
                        <label className="block text-sm text-gray-600 mb-1">Postal Code *</label>
                        <Input
                            value={address.isShippingSameAsBilling ? address.postalCode : address.shippingPostalCode}
                            onChange={(e) => handleAddressChange("shippingPostalCode", e.target.value)}
                            className="w-full rounded"
                            placeholder="Postal Code"
                            disabled={address.isShippingSameAsBilling}
                        />
                    </div>
                    <div>
                        <label className="block text-sm text-gray-600 mb-1">State *</label>
                        <div
                            style={{
                                pointerEvents: address.isShippingSameAsBilling ? "none" : "auto",
                                opacity: address.isShippingSameAsBilling ? 0.5 : 1,
                            }}
                        >
                            <MultipleSelect
                                options={Allstate}
                                label="State"
                                value={address.isShippingSameAsBilling ? address.state : address.shippingState}
                                defaultValue={address.isShippingSameAsBilling ? address.state : address.shippingState}
                                onChange={(selected) => handleAddressChange("shippingState", selected.target.value)}
                                name="state"
                                disabled={address.isShippingSameAsBilling}
                            />
                        </div>
                    </div>

                    <div>
                        <label className="block text-sm text-gray-600 mb-1">Country</label>
                        <select
                            value={address.isShippingSameAsBilling ? address.country : address.shippingCountry}
                            onChange={(e) => handleAddressChange("shippingCountry", e.target.value)}
                            className="border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5"
                            disabled={address.isShippingSameAsBilling}
                        >
                            <option value="">Choose Country</option>
                            <option value="India">India</option>
                            <option value="Australia">Australia</option>
                        </select>
                    </div>
                </div>
            </div>

            {/* Save Button */}
            <div onClick={handleSaveAddress} className="flex justify-between items-center my-5">
                <button className="bg-[#db3647] text-white px-4 py-2 rounded">
                    {isLoading && <CircularProgress size={12} />}
                    Save
                </button>
            </div>
        </div>
    );
};

export default ManageAddress;
