import { Input } from "antd";
import React, { useState } from "react";
import { FaPlusCircle, FaTrash } from "react-icons/fa";
import fetcher from "../../../../helper/fetcher";
import { useSnackbar } from "../../../../context/SnackBarContext";
import LoaderButton from "../../../../component/LoaderButton";

const AddItems = ({ setIsDrawerOpen, setRefetch }) => {
    const [styleName, setStyleName] = useState("");
    const { showSuccess, showError } = useSnackbar();
    const [rows, setRows] = useState([
        { itemName: "", avgValue: "", colQty: "", quantity: "", unitType: "", rateValue: "" },
    ]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const handleStyleNameChange = (e) => {
        setStyleName(e.target.value);
    };

    const handleInputChange = (index, field, value) => {
        const newRows = [...rows];
        newRows[index][field] = value;
        setRows(newRows);
    };

    const handleAddRow = () => {
        setRows([
            ...rows,
            { itemName: "", avgValue: "", colQty: "", quantity: "", unitType: "", rateValue: "" },
        ]);
    };

    const handleDeleteRow = (index) => {
        const newRows = rows.filter((_, i) => i !== index);
        setRows(newRows);
    };

    const handleSave = () => {
        if (!styleName.trim()) {
            showError("Style Name is required.");
            return;
        }

        for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            if (!row.itemName.trim()) {
                showError(`Item Name is required for Item-${i + 1}.`);
                return;
            }
            if (!row.avgValue.trim()) {
                showError(`Avg Value is required for Item-${i + 1}.`);
                return;
            }
            // if (!row.unitType.trim()) {
            //     showError(`Unit Type is required for Item-${i + 1}.`);
            //     return;
            // }
            if (!row.rateValue.trim()) {
                showError(`Rate Value is required for Item-${i + 1}.`);
                return;
            }
        }
        const formattedData = {
            item: rows.map(row => ({
                item_name: row.itemName,
                avg_pics: row.avgValue,
                unit: row.unitType,
                rate: row.rateValue,
            })),
            styleName: styleName,
            active: true,
        };
        setLoading(true);
        fetcher
            .post("/inventory/purchase_item/create_purchase_item", formattedData)
            .then((response) => {
                console.log("Save successful:", response.data);
                showSuccess(response.data.message)
                setTimeout(() => {
                    setIsDrawerOpen(false)
                }, 1000)
            })
            .catch((err) => {
                console.error("Save failed:", err);
                showError("Failed to save the data. Please try again.");
            })
            .finally(() => {
                setRefetch((pre) => pre + 1)
                setLoading(false);
            });
    };

    return (
        <div>
            {/* Content */}
            <div className="grid grid-cols-1 gap-4">
                <div>
                    <label className="block text-sm text-gray-600 mb-1">Style Name *</label>
                    <Input
                        value={styleName}
                        placeholder="Style Name"
                        onChange={handleStyleNameChange}
                        size="small"
                        className="w-full rounded"
                    />
                </div>

                <div className="">
                    <div className="space-y-4">
                        {rows.map((row, index) => (
                            <div
                                key={index}
                                className="p-2 bg-gray-100 px-3 py-5 rounded-[10px] border border-gray-300 mb-4"
                            >
                                <div className="flex flex-row justify-between items-center mb-4">
                                    <p className="font-bold text-gray-800 text-[16px]">Item-{index + 1}</p>

                                    {index !== 0 && (
                                        <button
                                            onClick={() => handleDeleteRow(index)}
                                            className="text-red-500 hover:text-red-700"
                                        >
                                            <FaTrash size={20} />
                                        </button>
                                    )}
                                </div>
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <label className="block text-sm text-gray-600 mb-1">Item Name *</label>
                                        <Input
                                            value={row.itemName}
                                            onChange={(e) =>
                                                handleInputChange(index, "itemName", e.target.value)
                                            }
                                            placeholder="Item Name"
                                            className="w-full rounded"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm text-gray-600 mb-1">Avg Value</label>
                                        <Input
                                            value={row.avgValue}
                                            onChange={(e) =>
                                                handleInputChange(index, "avgValue", e.target.value)
                                            }
                                            placeholder="Avg Value"
                                            className="w-full rounded"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm text-gray-600 mb-1">Unit Type</label>
                                        <Input
                                            value={row.unitType}
                                            onChange={(e) =>
                                                handleInputChange(index, "unitType", e.target.value)
                                            }
                                            placeholder="Unit Type"
                                            className="w-full rounded"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm text-gray-600 mb-1">Rate Value</label>
                                        <Input
                                            value={row.rateValue}
                                            onChange={(e) =>
                                                handleInputChange(index, "rateValue", e.target.value)
                                            }
                                            placeholder="Rate Value"
                                            className="w-full rounded"
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                        <button
                            onClick={handleAddRow}
                            className="flex items-center text-red-500 hover:text-red-700"
                        >
                            <FaPlusCircle size={20} className="mr-2" /> Add Row
                        </button>
                    </div>
                </div>

                <div className="flex justify-between items-center my-5">
                    <LoaderButton
                        label={"Save"}
                        isLoading={loading}
                        handleClick={() => handleSave()}
                    />
                </div>
            </div>
        </div>
    );
};

export default AddItems;
