import React from "react";
import moment from "moment";
import logo from "../../../assets/logo.jpeg";
import stamp from "../../../assets/stamp.jpeg";
import { convertNumberToWords, imageBaseUrl } from "../../../utils/utils";
import { Page, Text, View, Document, Image, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
    lineHeight: "13px"
  },
  header: {
    marginBottom: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 10,
  },
  logo: {
    width: "160px",
    height: "40px",
  },
  stamp: {
    width: "160px",
    height: "120px",
    objectFit: "cover"
  },
  section: {
    marginBottom: 10,
  },
  footer: {
    marginTop: 20,
    paddingTop: 10,
  },
  gridContainer: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
    marginTop: 20,
    gap: 10,
  },
  gridItem: {
    flex: 1,
    minWidth: "30%",
    paddingRight: 8,
  },
  margin: {
    marginBottom: "10px",
  },
  imageColumn: {
    width: "15%",
    textAlign: "center",
  },
  nameColumn: {
    width: "35%",
    textAlign: "left",
  },
  quantityColumn: {
    width: "10%",
    textAlign: "right",
  },
  unitPriceColumn: {
    width: "15%",
    textAlign: "right",
  },
  discountColumn: {
    width: "10%",
    textAlign: "right",
  },
  amountColumn: {
    width: "15%",
    textAlign: "right",
  },
  tableImage: {
    width: 50,
    height: 50,
    objectFit: "contain",
  },
  table: {
    marginTop: 10,
    borderColor: "#B0B0B0",
    borderLeftWidth: 1,
    borderTopWidth: 1,
    display: "table",
    width: "100%",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#B0B0B0",
  },
  tableCell: {
    flex: 1,
    borderRightWidth: 1,
    borderColor: "#B0B0B0",
    padding: 5,
    textAlign: "center",
  },
  tableHeader: {
    backgroundColor: "#f2f2f2",
    borderBottomWidth: 1,
    borderColor: "#B0B0B0",
  },
  lastTableCell: {
    borderRightWidth: 0,
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom: 10,

  },
  contentLeft: {
    maxWidth: "50%",

  },
  contentRight: {
    maxWidth: "50%",
  },

});

const InvoicePdf = ({ data }) => {
  console.log("data ====>>", data)
  const pro = JSON.parse(data.product[0])
  console.log(pro, "yoooooooooooooooooooooo")


  const subTotal = parseFloat(data.subTotal) || 0;
  const discount = parseFloat(data.totalDiscount) || 0;

  // Calculate Subtotal after Discount
  const subTotalAfterDiscount = subTotal - discount;

  // Calculate IGST (5% of Subtotal After Discount)
  const calculateIGST = (subTotal) => parseFloat((subTotal * 0.05).toFixed(2));
  const igst = calculateIGST(subTotalAfterDiscount);

  // Calculate Rounding Off
  const calculateRoundingOff = (subTotal, igst) => {
    const total = subTotal + igst;
    const roundedTotal = Math.round(total);
    return (roundedTotal - total).toFixed(2);
  };

  const roundingOff = calculateRoundingOff(subTotalAfterDiscount, igst);

  // Final Total (rounded value)
  const total = (subTotalAfterDiscount + igst + parseFloat(roundingOff)).toFixed(2);


  return (
    <Document>
      <Page style={[styles.page]} size="A4">
        {/* Header Section */}
        <View style={[styles.header]}>
          <Text style={[{ fontSize: 20, color: "#686868" }]}>PROFORMA INVOICE</Text>
          <Image src={logo} style={[styles.logo]} />
        </View>

        {/* Company Information */}
        <View style={styles.section}>
          <Text style={[styles.margin, { fontSize: 14 }]}>Amber Silk Mills</Text>
          <Text>9/1117, Jainmati Gali, Subhash Road, Gandhi Nagar</Text>
          <Text>DELHI, Delhi</Text>
          <Text>India 110031</Text>
          <Text>GSTIN: 07ADJPJ0004B1Z8</Text>
          <Text>State Name: Delhi Code: 07</Text>
        </View>

        {/* Billing Info */}
        <View style={[styles.section, styles.gridContainer]}>
          <View style={styles.gridItem}>
            <Text style={[styles.margin,]}>Bill To</Text>
            {/* Billing customer name*/}
            <Text>{data?.billTo?.customerName || "N/A"}</Text>
            {/* Billing AddressOne*/}
            <Text>{`${data?.billTo?.billingAddressOne || "N/A"}`}</Text>
            {/* Billing City*/}
            <Text>{data?.billTo?.billingCity ? `${data?.billTo?.billingCity} (town)` : "N/A"}</Text>
            {/* Billing State*/}
            <Text>{data?.billTo?.billingState || "N/A"}</Text>
            {/* Billing country and PostalCode*/}
            <Text>{`${data?.billTo?.country || "N/A"},${data?.billTo?.billingPostalCode || "N/A"}`}</Text>
            {/* Billing Phone Number */}
            <Text>Phone Number:{data?.billTo?.telephone || "N/A"}</Text>
            {/* Billing State Name and code */}
            <Text>State Name: {data?.billTo?.billingState || "N/A"} Code:{data?.billTo?.billingStateCode || "N/A"}</Text>
          </View>

          {/*  Shipping Info */}
          <View style={styles.gridItem}>
            <Text style={[styles.margin,]}>Ship To</Text>
            {/* <Text>{data?.shipTo?.customerName || "N/A"}</Text>
            <Text>{`${data?.shipTo?.shippingAddressOne || "N/A"}`}</Text>
            <Text>
              {data?.shipTo?.shippingCity && `${data?.shipTo?.shippingCity || "N/A"} (town)`}
            </Text>
            <Text>
              {data?.shipTo?.shippingState || "N/A"}
            </Text>
            <Text>{`${data?.shipTo?.country || "N/A"},${data?.shipTo?.shippingPostalCode || "N/A"}`}</Text>
            <Text>State Name: {data?.billTo?.shippingState || "N/A"} Code:{data?.billTo?.shippingStateCode || "N/A"}</Text> */}

            <Text>{data?.billTo?.customerName || "N/A"}</Text>
            {/* Billing AddressOne*/}
            <Text>{`${data?.billTo?.billingAddressOne || "N/A"}`}</Text>
            {/* Billing City*/}
            <Text>{data?.billTo?.billingCity ? `${data?.billTo?.billingCity} (town)` : "N/A"}</Text>
            {/* Billing State*/}
            <Text>{data?.billTo?.billingState || "N/A"}</Text>
            {/* Billing country and PostalCode*/}
            <Text>{`${data?.billTo?.country || "N/A"},${data?.billTo?.billingPostalCode || "N/A"}`}</Text>
            {/* Billing Phone Number */}
            <Text>Phone Number:{data?.billTo?.telephone || "N/A"}</Text>
            {/* Billing State Name and code */}
            <Text>State Name: {data?.billTo?.billingState || "N/A"}  Code:{data?.billTo?.billingStateCode || "N/A"}</Text>
            {/* GST */}
            <Text>GST Number: {data?.shipTo?.gstIn || "N/A"}</Text>
            <Text>GST Code: {data?.shipTo?.gstInCode || "N/A"}</Text>
          </View>

          <View style={styles.gridItem}>
            <Text style={styles.margin}>
              Dispatch Challan No: {data?.invoiceNo || "N/A"}
            </Text>
            <Text>
              Dispatch Challan Date: {data?.invoiceDate ? moment(data.invoiceDate).format("DD-MM-YYYY") : "Not Available"}
            </Text>
          </View>

        </View>

        <View >
          <Text style={[{ marginTop: 15 }]}>PLACE OF SUPPLY</Text>
          <Text>{data?.billTo?.billingState || "N/A"}</Text>
        </View>

        <View style={[styles.section, styles.gridContainer]}>
          <View style={styles.gridItem}>
            <Text>SHIPPING / DATE</Text>
            <Text>{data?.shippingDate ? moment(data.shippingDate).format("DD-MM-YYYY") : "N/A"}</Text>
          </View>
          <View style={styles.gridItem}>
            <Text>SALES PERSON</Text>
            <Text style={[{ marginBottom: 10 }]}> {data?.salesPerson || "N/A"}</Text>
          </View>
          <View style={styles.gridItem}>
            <Text>SALES PERSON CONTACT NO.</Text>
            <Text>{data?.salesPersonContactNo || "N/A"}</Text>
          </View>
          <View style={styles.gridItem}>
            <Text>SOURCE</Text>
            <Text>{data?.source || "N/A"}</Text>
          </View>
          <View style={styles.gridItem}>
            <Text >TRACKING ID</Text>
            <Text>{data?.trackingId || "N/A"}</Text>
          </View>
          <View style={styles.gridItem}>
            <Text>PAYMENT STATUS</Text>
            <Text>{data?.paymentStatus || "N/A"}</Text>
          </View>
          <View style={styles.gridItem}>
            <Text >PAYMENT DATE</Text>
            <Text>{data?.paymentDate ? moment(data.paymentDate).format("DD-MM-YYYY") : "N/A"}</Text>
          </View>
          <View style={styles.gridItem}>
            <Text >PAYMENT MODE</Text>
            <Text>{data?.paymentMode || "N/A"}</Text>
          </View>
        </View>

        {/* Improved Table */}
        <View style={styles.table}>
          {/* Table Header */}
          <View style={[styles.tableRow, styles.tableHeader]}>
            <Text style={[styles.tableCell, styles.imageColumn]}>Image</Text>
            <Text style={[styles.tableCell, styles.nameColumn]}>Name</Text>
            <Text style={[styles.tableCell, styles.nameColumn]}>Description</Text>
            <Text style={[styles.tableCell, styles.quantityColumn]}>Qty</Text>
            <Text style={[styles.tableCell, styles.unitPriceColumn]}>Unit Price</Text>
            <Text style={[styles.tableCell, styles.discountColumn]}>Discount</Text>
            <Text style={[styles.tableCell, styles.amountColumn]}>Amount</Text>
          </View>

          {/* Table Data */}
          {pro && pro.map((item, index) => (
            <View
              key={index}
              style={[
                styles.tableRow,
                index % 2 === 0 ? styles.rowEven : styles.rowOdd,
              ]}
            >
              {/* Image */}
              <View style={[styles.tableCell, styles.imageColumn]}>
                {item?.product_image ? <Image src={`${imageBaseUrl}images/${item?.product_image?.url}`} style={styles.tableImage} /> : null}
              </View>
              {/* Name */}
              <Text style={[styles.tableCell, styles.nameColumn]}>
                {item.product}
                <Text style={{ marginTop: 5, fontSize: 9 }}>
                  {"\n"}HSN/SAC: 5407
                </Text>
              </Text>
              <Text style={[styles.tableCell, styles.quantityColumn]}>
                {item.description || "N/A"}
              </Text>
              {/* Quantity */}
              <Text style={[styles.tableCell, styles.quantityColumn]}>
                {item.quantity}
              </Text>
              {/* Unit Price */}
              <Text style={[styles.tableCell, styles.unitPriceColumn]}>
                {item.price}
              </Text>
              {/* Discount */}
              <Text style={[styles.tableCell, styles.discountColumn]}>
                {item.discount}
              </Text>
              {/* Amount */}
              <Text style={[styles.tableCell, styles.amountColumn]}>
              {(item.quantity * item.price).toFixed(2)}
              </Text>
            </View>
          ))}
        </View>

        <View style={styles.container}>
          <View style={styles.contentLeft}>
            {/* Other content or elements can go here */}
          </View>
          <View style={[styles.table, styles.contentRight]}>
            {/* Table Data */}
            {[
              {
                label: "Subtotal",
                value: subTotal.toFixed(2),
              },
              {
                label: "Discount",
                value: discount.toFixed(2),
              },
              {
                label: "Subtotal After Discount",
                value: subTotalAfterDiscount.toFixed(2),
              },
              {
                label: "GST",
                value: igst,
              },
              {
                label: "Rounding Off",
                value: roundingOff,
              },
              {
                label: "Total",
                value: total,
                isHighlighted: true,
              },
              {
                label: "Amount In Words",
                value: convertNumberToWords(parseInt(total)),
                isHighlighted: true,

              }].map((item, index) => (
                <View
                  key={index}
                  style={[styles.tableRow, item.isHighlighted ? { backgroundColor: '#f2f2f2' } : null]}
                >
                  {/* Label */}
                  <Text style={[styles.tableCell, styles.labelColumn]}>
                    {item.label}
                  </Text>
                  {/* Value */}
                  <Text style={[styles.tableCell, styles.valueColumn]}>
                    {item.value}
                  </Text>
                </View>
              ))}
          </View>
        </View>

        <View style={[styles.table, styles.additionalTable]}>
          {/* Header Row */}
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.headerColumn, { flex: 1 }]}>HSN/SAC</Text>
            <Text style={[styles.tableCell, styles.headerColumn, { flex: 1.5 }]}>Taxable Value</Text>
            <Text style={[styles.tableCell, styles.headerColumn, { flex: 2 }]}>Integrated Tax</Text>
            <Text style={[styles.tableCell, styles.headerColumn, { flex: 1.5 }]}>Total Tax Amount</Text>
          </View>

          {/* Sub-header Row */}
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, { flex: 1 }]}></Text>
            <Text style={[styles.tableCell, { flex: 1.5 }]}></Text>
            <View style={[styles.tableCell, { flex: 2, flexDirection: 'row' }]}>
              <Text style={{ flex: 1, textAlign: 'center' }}>Rate</Text>
              <Text style={{ flex: 1, textAlign: 'center' }}>Amount</Text>
            </View>
            <Text style={[styles.tableCell, { flex: 1.5 }]}></Text>
          </View>

          {/* Data Rows */}
          {[{
            hsn: "5407",
            taxableValue: data.subTotal,
            taxRate: "5%",
            taxAmount: igst,
            totalTax: igst,
          }, {
            hsn: "Total",
            taxableValue: data.subTotal,
            taxRate: "",
            taxAmount: igst,
            totalTax: igst,
          }].map((row, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={[styles.tableCell, { flex: 1 }]}>{row.hsn}</Text>
              <Text style={[styles.tableCell, { flex: 1.5 }]}>{row.taxableValue}</Text>
              <View style={[styles.tableCell, { flex: 2, flexDirection: 'row' }]}>
                <Text style={{ flex: 1, textAlign: 'center' }}>{row.taxRate}</Text>
                <Text style={{ flex: 1, textAlign: 'center' }}>{row.taxAmount}</Text>
              </View>
              <Text style={[styles.tableCell, { flex: 1.5 }]}>{row.totalTax}</Text>
            </View>
          ))}
        </View>

        <View style={[styles.Right, { flexDirection: 'row', justifyContent: 'flex-end', marginTop: 15 }]}>
          <Image src={stamp} style={styles.stamp} />
        </View>

        {/* Footer */}
        <View style={styles.footer}>
          <Text style={[{ marginTop: 10, marginBottom: 5 }]}>TERMS & CONDITIONS</Text>
          <Text>1. Goods once sold will not be taken back or exchanged.</Text>
          <Text>2. Any other services, if required, will be charged extra.</Text>
          <Text>3. Goods are dispatched & delivered at owner's risk.</Text>
          <Text>4. We are not responsible for any loss or damage of goods in transit.</Text>
          <Text>5. Payment to be made on the mentioned UPI number & bank accounts only.</Text>
          <Text>6. This is not the final GST invoice. Once payment is received, a new GST invoice will be issued.</Text>
          <Text>7. Actual product and quality may differ from the selected one due to picture quality.</Text>
          <Text>8. We recommend dry cleaning for our products.</Text>
          <Text style={[{ marginTop: 20, marginBottom: 5, color: "gray" }]}>Bank Details</Text>
          <Text>Name: Amber Silk Mills</Text>
          <Text>A/C No: 19812320000367</Text>
          <Text>Bank: HDFC Bank Ltd.</Text>
          <Text>Branch: Nai Sarak, Delhi</Text>
          <Text>IFSC: HDFC0001981</Text>
          <Text style={[{ marginTop: 15 }]}>GOOGLE PAY , PHONE PAY , PAYTM,</Text>
          <Text>AMBER SILK MILLS - 9871079301</Text>

        </View>
        <Text style={{ textAlign: "center", marginTop: 15, fontStyle: "italic" }}>This is a Computer Generated Challan.</Text>
      </Page>
    </Document>
  );
};



export default InvoicePdf;
