import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LongMenu from "./LongMenu";
import WarningModal from './WarningModal';

const CollapsibleTable = ({
    tableData,
    handleDeleteItems,
    setEdit,
    updatefun,
    state }) => {
    const rows = tableData.map((item) => ({
        name: item.styleName,
        id: item._id,
        history: item.item.map((i) => ({
            itemName: i.item_name,
            avg: i.avg_pics,
            unit: i.unit,
            rate: i.rate,
        })),
    }));

    function Row(props) {
        const { row, index } = props;
        const [open, setOpen] = useState(false);

        const menuOptions = [
            // { id: 1, title: "Edit", icon: <EditIcon /> },
            { id: 2, title: "Delete", icon: <DeleteIcon />, color: "red" }
        ];

        const handleOptions = (item, row) => {
            if (item.id == 1) {
                setEdit(row)
            } else if (item.id == 2) {
                updatefun({ id: row.id, isModalOpen: true })
            }
        }

        return (
            <>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    {/* Add smaller padding for Sr. No. and Style Name */}
                    <TableCell sx={{ paddingLeft: '12px', paddingRight: '0px' }}>
                        {index + 1}
                    </TableCell>
                    <TableCell sx={{ paddingLeft: '4px', paddingRight: '8px' }} component="th" scope="row">
                        {row.name}
                    </TableCell>
                    <TableCell align="right" sx={{ display: "flex" }}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                        <LongMenu
                            options={menuOptions}
                            handleOptions={(prev) => handleOptions(prev, row)}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Table size="small" aria-label="purchases">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <Typography fontWeight="bold">Item Name *</Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography fontWeight="bold">Avg</Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography fontWeight="bold">Unit</Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography fontWeight="bold">Rate</Typography>
                                            </TableCell>
                                        </TableRow>
                                        {row.history.map((historyRow, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{historyRow.itemName}</TableCell>
                                                <TableCell align="right">{historyRow.avg}</TableCell>
                                                <TableCell align="right">{historyRow.unit}</TableCell>
                                                <TableCell align="right">{historyRow.rate}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        );
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableBody>
                        {rows.map((row, index) => (
                            <Row key={index} row={row} index={index} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <WarningModal
                isOpen={state.isModalOpen}
                onClose={() => updatefun({ isModalOpen: false })}
                modalTitle="Are you sure you want to delete this item?"
                onConfirm={handleDeleteItems}
                onCancel={() => updatefun({ isModalOpen: false })}
                confirmText={state.isLoading ? "Deleting..." : "Delete"}
                cancelText="Cancel"
                isLoading={state.isLoading}
            />
        </>
    );
}

export default CollapsibleTable
