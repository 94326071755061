import React from "react";
import moment from "moment";
import stamp from "../../../../assets/cloth.png";
import { Page, Text, View, Document, Image, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    page: {
        padding: 20,
        fontSize: 10,
        fontFamily: "Helvetica-Bold",
        lineHeight: "13px"
    },
    header: {

        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 10,
    },
    border: {
        paddingBottom: 20,
        borderBottomWidth: 1,
        borderBottomColor: "black",
    },
    logo: {
        width: "160px",
        height: "40px",
    },
    stamp: {
        width: "400px",
        height: "250px",
        objectFit: "cover"
    },
    section: {
        marginBottom: 10,
    },
    footer: {
        marginTop: 20,
        paddingTop: 10,
    },
    gridContainer: {
        flexDirection: "row",
        justifyContent: "space-evenly",
        flexWrap: "wrap",
        marginTop: 20,
        gap: 10,
    },
    gridItem: {
        flex: 1,
        minWidth: "30%",
        paddingRight: 8,
    },
    margin: {
        marginBottom: "10px",
    },
    imageColumn: {
        width: "15%",
        textAlign: "center",
    },
    nameColumn: {
        width: "35%",
        textAlign: "left",
    },
    quantityColumn: {
        width: "10%",
        textAlign: "right",
    },
    unitPriceColumn: {
        width: "15%",
        textAlign: "right",
    },
    discountColumn: {
        width: "10%",
        textAlign: "right",
    },
    amountColumn: {
        width: "15%",
        textAlign: "right",
    },
    tableImage: {
        width: 50,
        height: 50,
        objectFit: "contain",
    },
    table: {
        marginTop: 10,
        borderColor: "#B0B0B0",
        borderLeftWidth: 1,
        borderTopWidth: 1,
        display: "table",
        width: "100%",
    },
    tableRow: {
        flexDirection: "row",
        borderBottomWidth: 1,
        borderColor: "#B0B0B0",
    },
    tableCell: {
        flex: 1,
        borderRightWidth: 1,
        borderColor: "#B0B0B0",
        padding: 5,
        textAlign: "center",
    },
    tableHeader: {
        backgroundColor: "#f2f2f2",
        borderBottomWidth: 1,
        borderColor: "#B0B0B0",
    },
    lastTableCell: {
        borderRightWidth: 0,
    },
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginBottom: 10,

    },
    contentLeft: {
        maxWidth: "50%",

    },
    contentRight: {
        maxWidth: "50%",
    },

});

const ProductPdf = ({ data }) => {
    console.log("data ====>>", data)
    const pro = JSON.parse(data.product[0])
    console.log(pro, "yoooooooooooooooooooooo")


    const subTotal = parseFloat(data.subTotal) || 0;
    const discount = parseFloat(data.totalDiscount) || 0;

    // Calculate Subtotal after Discount
    const subTotalAfterDiscount = subTotal - discount;

    // Calculate IGST (5% of Subtotal After Discount)
    const calculateIGST = (subTotal) => parseFloat((subTotal * 0.05).toFixed(2));
    const igst = calculateIGST(subTotalAfterDiscount);

    // Calculate Rounding Off
    const calculateRoundingOff = (subTotal, igst) => {
        const total = subTotal + igst;
        const roundedTotal = Math.round(total);
        return (roundedTotal - total).toFixed(2);
    };

    const roundingOff = calculateRoundingOff(subTotalAfterDiscount, igst);

    // Final Total (rounded value)
    const total = (subTotalAfterDiscount + igst + parseFloat(roundingOff)).toFixed(2);


    return (
        <Document>
            <Page style={[styles.page]} size="A4">
                {/* Header Section */}
                <View style={[styles.header, styles.border]}>
                    <Text style={[{ fontSize: 16, color: "black" }]}>ASM</Text>
                    <Text style={[{ fontSize: 14, color: "black", marginTop: 20 }]}>ITEM PURCHASE ORDER</Text>
                </View>

                <View style={[styles.gridContainer, styles.border]}>
                    <View style={styles.gridItem}>
                        <View style={{ flexDirection: "row" }}>
                            <View >
                                <Text style={{ fontSize: 11, marginTop: 5, marginBottom: 3, marginRight: 6 }}>
                                    Buyer
                                </Text>
                                <Text style={{ fontSize: 11, marginTop: 5, marginBottom: 3, marginRight: 6 }}>
                                    Supplier
                                </Text>
                                <Text style={{ fontSize: 11, marginTop: 5, marginBottom: 3, marginRight: 6 }}>
                                    Size
                                </Text>
                                <Text style={{ fontSize: 11, marginTop: 5, marginBottom: 3, marginRight: 6 }}>
                                    Fabricator
                                </Text>
                                <Text style={{ fontSize: 11, marginTop: 5, marginBottom: 3, marginRight: 6 }}>
                                    Style No
                                </Text>
                                <Text style={{ fontSize: 11, marginTop: 5, marginBottom: 3, marginRight: 6 }}>
                                    Style Name
                                </Text>
                                <Text style={{ fontSize: 11, marginTop: 5, marginBottom: 3, marginRight: 6 }}>
                                    Remarks
                                </Text>
                            </View>
                            <View >
                                <Text style={{ fontSize: 11, marginTop: 5, marginBottom: 3 }}>
                                    :    {data?.invoiceNo || "N/A"}
                                </Text>

                                <Text style={{ fontSize: 11, marginTop: 5, marginBottom: 3 }}>
                                    :    {"Not Available"}
                                </Text>
                                <Text style={{ fontSize: 11, marginTop: 5, marginBottom: 3 }}>
                                    :    {data?.invoiceDate ? moment(data.invoiceDate).format("DD-MM-YYYY") : "Not Available"}
                                </Text>
                                <Text style={{ fontSize: 11, marginTop: 5, marginBottom: 3 }}>
                                    :    {data?.invoiceDate ? moment(data.invoiceDate).format("DD-MM-YYYY") : "Not Available"}
                                </Text>
                                <Text style={{ fontSize: 11, marginTop: 5, marginBottom: 3 }}>
                                    :    {data?.invoiceDate ? moment(data.invoiceDate).format("DD-MM-YYYY") : "Not Available"}
                                </Text>
                                <Text style={{ fontSize: 11, marginTop: 5, marginBottom: 3 }}>
                                    :    {data?.invoiceDate ? moment(data.invoiceDate).format("DD-MM-YYYY") : "Not Available"}
                                </Text>
                                <Text style={{ fontSize: 11, marginTop: 5, marginBottom: 3 }}>
                                    :    {data?.invoiceDate ? moment(data.invoiceDate).format("DD-MM-YYYY") : "Not Available"}
                                </Text>

                            </View>
                        </View>
                    </View>

                    <View style={styles.gridItem}>
                        <View style={{ flexDirection: "row" }}>
                            <View >
                                <Text style={{ fontSize: 11, marginTop: 5, marginBottom: 3, marginRight: 6 }}>
                                    Order No
                                </Text>
                                <Text style={{ fontSize: 11, marginTop: 5, marginBottom: 3, marginRight: 6 }}>
                                    Dated
                                </Text>
                                <Text style={{ fontSize: 11, marginTop: 5, marginBottom: 3, marginRight: 6 }}>
                                    Delivery Date
                                </Text>
                                <Text style={{ fontSize: 11, marginTop: 5, marginBottom: 3, marginRight: 6 }}>
                                    Delivery at
                                </Text>
                                <Text style={{ fontSize: 11, marginTop: 5, marginBottom: 3, marginRight: 6 }}>
                                    Issue Qty
                                </Text>
                            </View>
                            <View >
                                <Text style={{ fontSize: 11, marginTop: 5, marginBottom: 3 }}>
                                    :    {data?.invoiceNo || "N/A"}
                                </Text>

                                <Text style={{ fontSize: 11, marginTop: 5, marginBottom: 3 }}>
                                    :    {data?.invoiceDate ? moment(data.invoiceDate).format("DD-MM-YYYY") : "Not Available"}
                                </Text>
                                <Text style={{ fontSize: 11, marginTop: 5, marginBottom: 3 }}>
                                    :    {data?.invoiceDate ? moment(data.invoiceDate).format("DD-MM-YYYY") : "Not Available"}
                                </Text>
                                <Text style={{ fontSize: 11, marginTop: 5, marginBottom: 3 }}>
                                    :    {data?.invoiceDate ? moment(data.invoiceDate).format("DD-MM-YYYY") : "Not Available"}
                                </Text>
                                <Text style={{ fontSize: 11, marginTop: 5, marginBottom: 3 }}>
                                    :    {data?.invoiceDate ? moment(data.invoiceDate).format("DD-MM-YYYY") : "Not Available"}
                                </Text>

                            </View>
                        </View>
                    </View>
                </View>
                <View style={{ flexDirection: "row", marginTop: 10 }}>
                    <View >
                        <Text style={styles.margin}>
                            Color: {data?.invoiceNo || "N/A"}
                        </Text>

                    </View>
                    <View >
                        <Text style={{ marginLeft: 120 }}>
                            STAR GEORGET DUSTY CHART
                        </Text>

                    </View>
                </View>


                {/* Improved Table */}
                <View style={styles.table}>
                    {/* Table Header */}
                    <View style={[styles.tableRow, styles.tableHeader]}>

                        <Text style={[styles.tableCell, styles.nameColumn]}>Name</Text>
                        <Text style={[styles.tableCell, styles.imageColumn]}>Avg(1 Pcs)</Text>
                        <Text style={[styles.tableCell, styles.nameColumn]}>1 Col. Qty</Text>
                        <Text style={[styles.tableCell, styles.quantityColumn]}>Quantity</Text>
                        <Text style={[styles.tableCell, styles.unitPriceColumn]}>Unit</Text>
                        <Text style={[styles.tableCell, styles.amountColumn]}>Rate</Text>
                    </View>

                    {/* Table Data */}
                    {pro && pro.map((item, index) => (
                        <View
                            key={index}
                            style={[
                                styles.tableRow,
                                index % 2 === 0 ? styles.rowEven : styles.rowOdd,
                            ]}
                        >
                            {/* Name */}
                            <Text style={[styles.tableCell, styles.nameColumn]}>
                                {item.product}
                            </Text>
                            {/* Image */}
                            <View style={[styles.tableCell, styles.imageColumn]}>
                                {item.image ? <Image src={item.image} style={styles.tableImage} /> : null}
                            </View>
                            <Text style={[styles.tableCell, styles.quantityColumn]}>
                                {"N/A"}
                            </Text>
                            {/* Quantity */}
                            <Text style={[styles.tableCell, styles.quantityColumn]}>
                                {item.quantity}
                            </Text>
                            {/* Unit Price */}
                            <Text style={[styles.tableCell, styles.unitPriceColumn]}>
                                {item.price}
                            </Text>
                            {/* Amount */}
                            <Text style={[styles.tableCell, styles.amountColumn]}>
                                {(item.quantity * item.price).toFixed(2)}
                            </Text>
                        </View>
                    ))}
                </View>

                <View style={[styles.border, { flexDirection: "row", justifyContent: "flex-end", marginTop: 20 }]}>
                    <Text>
                        Total  :  {data?.invoiceDate ? moment(data.invoiceDate).format("DD-MM-YYYY") : "Not Available"}
                    </Text>
                </View>


                <View style={[{ flexDirection: 'row', justifyContent: 'flex-start', marginTop: 10 }]}>
                    <Image src={stamp} style={styles.stamp} />
                </View>

                <View style={[styles.Right, { flexDirection: 'row', justifyContent: 'flex-end', marginTop: 5, marginRight: 10 }]}>
                    <Text style={{ borderTopWidth: 1, borderColor: 'black', paddingTop: 5, alignSelf: "flex-end" }}>
                        Authorized Sign
                    </Text>
                </View>

            </Page>
        </Document>
    );
};



export default ProductPdf;
