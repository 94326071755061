import fetcher from '../../helper/fetcher'
import React, { useEffect, useState } from 'react'
import WarningModal from '../../component/WarningModal'
import { useSnackbar } from '../../context/SnackBarContext'
import AllProduct from '../../features/Inventory/ManageProduct/AllProduct'

const ManageProduct = () => {
    const { showSuccess, showError } = useSnackbar();
    const [state, setState] = useState({
        query: "",
        isModalOpen: false,
        isLoading: false,
        id: null
    })
    const [data, setData] = useState({
        results: [],
        page: 0,
        limit: 0,
        totalPages: 0,
        totalResults: 0,
    })
    const [params, setParams] = useState({
        page: 1,
        limit: 20,
    })

    const setPage = (page) => {
        setParams({ ...params, page: page });
    };
    const [refetch, setRefetch] = useState(1)

    const updateState = (data) => {
        setState((prevState) => {
            const newState = { ...prevState, ...data }
            return newState
        })
    }

    const handleGetAllProduct = (val) => {
        fetcher.get(`/inventory/product/get_all_product?query=${state.query}&page=${val != undefined ? val : params.page}&limit=${params.limit}`)
            .then((res) => {
                setData({
                    ...res.data
                });
            })
            .catch((error) => {
                showError(error.response?.data?.mgs);
            }).finally(() => {
                updateState({ isLoading: false, query: "" })
            })
    }

    useEffect(() => {
        handleGetAllProduct()
    }, [refetch,  params])

    const handleDeleteProduct = () => {
        if (!state.id) return;
        updateState({ isLoading: true })
        fetcher.delete(`inventory/product/delete_product/${state.id}`)
            .then((res) => {
                showSuccess("Deleted successfully")
            })
            .catch((error) => {
                showError("Some Error Occured");
            }).finally(() => {
                setRefetch((pre) => pre + 1)
                updateState({ isLoading: false, isModalOpen: false })
            })
    }

    return (
        <div>
            <AllProduct
                updateFunc={updateState}
                handleGetAllProduct={(val) => handleGetAllProduct(val)}
                parentState={state}
                data={data}
                setPage={setPage}
                setRefetch={setRefetch}
            />
            <WarningModal
                isOpen={state.isModalOpen}
                onClose={() => updateState({ isModalOpen: false })}
                modalTitle="Are you sure you want to delete this item?"
                onConfirm={handleDeleteProduct}
                onCancel={() => updateState({ isModalOpen: false })}
                confirmText={state.isLoading ? "Deleting..." : "Delete"}
                cancelText="Cancel"
                isLoading={state.isLoading}
            />
        </div>
    )
}

export default ManageProduct